import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AngleViewer } from "../../common/AngleViewer";
import { CustomFab } from "../../common/CustomFab";

interface Props {
  mainTitle: string;
  dialogTitle: string;
  minValue: number;
  maxValue: number;
  setMinValue: React.Dispatch<React.SetStateAction<number>>;
  setMaxValue: React.Dispatch<React.SetStateAction<number>>;
  updateDeviceConfiguration: () => void;
}

const AngleSelector = (props: Props) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [minValueString, setMinValueString] = useState(
    props.minValue.toString()
  );
  const [maxValueString, setMaxValueString] = useState(
    props.maxValue.toString()
  );
  const [minValueError, setMinValueError] = useState<string>("");
  const [maxValueError, setMaxValueError] = useState<string>("");

  useEffect(() => {
    setMinValueString(props.minValue.toString());
  }, [props.minValue]);

  useEffect(() => {
    setMaxValueString(props.maxValue.toString());
  }, [props.maxValue]);

  const isValueValid = (value: string) => {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue >= -128 && parsedValue <= 128;
  };

  const handleChangeMinValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setMinValueString(newValue);

    handleChangeMinValueValidation(newValue);
  };

  const handleChangeMinValueValidation = (newValue: string) => {
    if (isMinValueValid(newValue)) {
      setMinValueError("");
      const parsedMinValue = parseFloat(newValue);
      props.setMinValue(parsedMinValue);
      if (isMaxValueValid(maxValueString)) {
        const parsedMaxValue = parseFloat(maxValueString);
        props.setMaxValue(parsedMaxValue);
      }
    } else {
      setMinValueError(
        "Value must be a number within the range -128 to 128 and should be less than max value"
      );
    }
  };

  const isMinValueValid = (value: string) => {
    if (isValueValid(value)) {
      const parsedValue = parseFloat(value);
      return parsedValue <= props.maxValue;
    }
  };

  const handleChangeMaxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setMaxValueString(newValue);

    handleChangeMaxValueValidation(newValue);
  };

  const handleChangeMaxValueValidation = (newValue: string) => {
    if (isMaxValueValid(newValue)) {
      setMaxValueError("");
      const parsedMaxValue = parseFloat(newValue);
      props.setMaxValue(parsedMaxValue);
      if (isMinValueValid(minValueString)) {
        const parsedMinValue = parseFloat(minValueString);
        props.setMinValue(parsedMinValue);
      }
    } else {
      setMaxValueError(
        "Value must be a number within the range -128 to 128 and should be greater than min value"
      );
    }
  };

  const isMaxValueValid = (value: string) => {
    if (isValueValid(value)) {
      const parsedValue = parseFloat(value);
      return parsedValue >= props.minValue;
    }
  };

  const onDialogClose = () => {
    if (minValueError === "" && maxValueError === "") {
      setOpenDialog(false);
      props.updateDeviceConfiguration();
    }
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>{props.mainTitle} </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Typography sx={{ pr: 1 }}>{`${props.minValue}°`}</Typography>
          <Typography sx={{ pr: 1 }}>{`${props.maxValue}°`}</Typography>

          <Box>
            <CustomFab
              icon={<EditIcon />}
              onClick={() => setOpenDialog(true)}
            />
          </Box>
        </Box>
      </Grid>

      <Dialog fullScreen open={openDialog} onClose={onDialogClose}>
        <Box sx={{ m: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">{props.dialogTitle}</Typography>
          </Box>
          <Stack
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            spacing={2}
          >
            <TextField
              type="number"
              fullWidth
              InputLabelProps={{
                style: { color: "#1287A8" },
              }}
              size="small"
              id="max-value"
              label="Max Value"
              value={maxValueString}
              onChange={handleChangeMaxValue}
              error={!!maxValueError}
              helperText={maxValueError}
              sx={{
                m: 0.5,
                minWidth: 150,
                maxWidth: 350,
              }}
            />
            <TextField
              type="number"
              fullWidth
              InputLabelProps={{
                style: { color: "#dc5304" },
              }}
              size="small"
              id="min-value"
              label="Min Value"
              value={minValueString}
              onChange={handleChangeMinValue}
              error={!!minValueError}
              helperText={minValueError}
              sx={{
                m: 0.5,
                maxWidth: 350,
              }}
            />
          </Stack>
          <AngleViewer minValue={props.minValue} maxValue={props.maxValue} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={onDialogClose}
              disabled={minValueError !== "" || maxValueError !== ""}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export { AngleSelector };
