import { Grid, Typography } from "@mui/material";

interface Props {
  blockedControls: boolean[];
}

const getLastBlockedControl = (blockedControls: boolean[]) => {
  const blockedControlsMessages = [
    "outside right",
    "outside left",
    "inside",
    "footswitch",
  ];

  // Only the first 4 elements are used.
  // The other are reserve and are not relevant.
  const blockedControlsArray = blockedControls.slice(0, 4);

  const activeMessages = blockedControlsArray
    .map((isBlocked, index) =>
      isBlocked ? blockedControlsMessages[index] : null
    )
    .filter(Boolean);

  if (activeMessages.length === 0) {
    return "";
  }

  return activeMessages.join(" & ");
};

const BlockedCableControlComponent = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Blocked cable control </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Typography>{getLastBlockedControl(props.blockedControls)}</Typography>
      </Grid>
    </>
  );
};

export { BlockedCableControlComponent };
