import { Chip, Grid, Typography } from "@mui/material";
import {
  HandheldConnectionStateColor,
  HandheldConnectionStateEnum,
  HandheldConnectionStateMessage,
} from "../../../models/dto/ws/monitoring/HandheldConnectionStateEnum";

interface Props {
  handheldConnectionState: HandheldConnectionStateEnum;
}

const HandheldConnectionStateComponent = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Connection state </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {props.handheldConnectionState !==
          HandheldConnectionStateEnum.NO_TLG && (
          <Chip
            variant="outlined"
            label={
              HandheldConnectionStateMessage[props.handheldConnectionState] ??
              "Unknown"
            }
            color={
              HandheldConnectionStateColor[props.handheldConnectionState] ??
              "default"
            }
          />
        )}
      </Grid>
    </>
  );
};

export { HandheldConnectionStateComponent };
