import { OverridableStringUnion } from "@mui/types";

export enum PlatformSensorWarningEnum {
  PLATFORM_SENSOR_STATE_LOW_VOLTAGE = -30,
  PLATFORM_SENSOR_STATE_IMU_VAL_NOT_PLAUSIBLE = -14,
  PLATFORM_SENSOR_STATE_CALIB_ERROR = -13,
  PLATFORM_SENSOR_STATE_IMU_COM_ERROR = -12,
  PLATFORM_SENSOR_STATE_INTERNAL_COM_MC_SC_ERROR = -11,
  PLATFORM_SENSOR_STATE_BLINK_OUT_SHORT_CIRCUIT = -10,
  PLATFORM_SENSOR_STATE_NOT_SUPPORTED = -2,
  PLATFORM_SENSOR_STATE_NOT_CONNECTED = -1,
  PLATFORM_SENSOR_STATE_OK = 0,
}

export const PlatformSensorWarningMessage: Record<
  PlatformSensorWarningEnum,
  string
> = {
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_LOW_VOLTAGE]:
    "Platform sensor supply <10V",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_IMU_VAL_NOT_PLAUSIBLE]:
    "IMU values not plausible",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_CALIB_ERROR]:
    "Internal calibration error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_IMU_COM_ERROR]:
    "IMU communication error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_INTERNAL_COM_MC_SC_ERROR]:
    "Internal communication error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_BLINK_OUT_SHORT_CIRCUIT]:
    "Flashing output is short-circuited",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_NOT_SUPPORTED]:
    "Error state not supported",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_NOT_CONNECTED]:
    "Platform sensor is not connected",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_OK]: "OK",
};

type SensorWarningColor = OverridableStringUnion<
  "default" | "error" | "success"
>;

export const PlatformSensorWarningColor: Record<
  PlatformSensorWarningEnum,
  SensorWarningColor
> = {
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_LOW_VOLTAGE]: "error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_IMU_VAL_NOT_PLAUSIBLE]:
    "error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_CALIB_ERROR]: "error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_IMU_COM_ERROR]: "error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_INTERNAL_COM_MC_SC_ERROR]:
    "error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_BLINK_OUT_SHORT_CIRCUIT]:
    "error",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_NOT_SUPPORTED]: "default",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_NOT_CONNECTED]: "default",
  [PlatformSensorWarningEnum.PLATFORM_SENSOR_STATE_OK]: "success",
};
