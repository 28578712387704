import { useEffect, useState } from "react";
import {
  AbstractActivatedSensors,
  AbstractMonitorMessage,
  AbstractStatusPayload,
  CabinSwitch,
  HandheldLimitation,
  InputInterface,
  InputInterfaceV2,
  Lock,
  MonitorAutoTiltDown,
  OutputA,
  OutputB,
  PlatformSensorV0V1,
  PlatformSensorV2,
  StatusPayloadV0,
  StatusPayloadV1,
  StatusPayloadV2,
  SystemLimitation,
} from "../../../cobra-backend-client";
import {
  STATUS_MONITOR_VERSION_0,
  STATUS_MONITOR_VERSION_1,
  STATUS_MONITOR_VERSION_2,
} from "../../../constants/common";
import { ActiveCableControlEnum } from "../../../models/dto/ws/monitoring/ActiveCableControlEnum";
import { HandheldConnectionStateEnum } from "../../../models/dto/ws/monitoring/HandheldConnectionStateEnum";
import { MonitorErrorCodeEnum } from "../../../models/dto/ws/monitoring/MonitorErrorCodeEnum";
import { RssiZoneEnum } from "../../../models/dto/ws/monitoring/RssiZoneEnum";
import { DeviceStatusController } from "./DeviceStatusController";
import { DeviceStatusHandheld } from "./DeviceStatusHandheld";
import { DeviceStatusPlatformSensorContainer } from "./DeviceStatusPlatformSensorContainer";
import { DeviceStatusSystem } from "./DeviceStatusSystem";

interface Props {
  monitorMessage: AbstractMonitorMessage;
}

const DeviceStatus = (props: Props) => {
  const [activatedSensors, setActivatedSensors] =
    useState<AbstractActivatedSensors>();
  const [analogIo, setAnalogIo] = useState<number>();
  const [platformSensor, setPlatformSensor] = useState<
    PlatformSensorV0V1 | PlatformSensorV2
  >();
  const [inputInterface, setInputInterface] = useState<
    InputInterface | InputInterfaceV2
  >();

  const [errorCode, setErrorCode] = useState<MonitorErrorCodeEnum>();
  const [handheldLimitation, setHandheldLimitation] =
    useState<HandheldLimitation>();
  const [systemLimitation, setSystemLimitation] = useState<SystemLimitation>();
  const [rssiZone, setRssiZone] = useState<RssiZoneEnum>();
  const [hhButtons, setHhButtons] = useState<boolean[]>();
  const [outputA, setOutputA] = useState<OutputA>();
  const [outputB, setOutputB] = useState<OutputB>();
  const [platformAngle, setPlatformAngle] = useState<number>();
  const [handheldSignal, setHandheldSignal] = useState<number>();
  const [wlanIndex, setWlanIndex] = useState<number>();
  const [batteryLevel, setBatteryLevel] = useState<number>();
  const [autotiltDown, setAutotiltDown] = useState<MonitorAutoTiltDown>();
  const [cabinSwitch, setCabinSwitch] = useState<CabinSwitch>();
  const [lock, setLock] = useState<Lock>();
  const [statusMonitorPayloadVersion, setStatusMonitorPayloadVersion] =
    useState<string>();
  const [activeCableControl, setActiveCableControl] =
    useState<ActiveCableControlEnum>();
  const [blockedControls, setBlockedControls] = useState<boolean[]>();
  const [activeFunctions, setActiveFunctions] = useState<boolean[]>();
  const [batteryIndicator, setBatteryIndicator] = useState<number>();
  const [handheldConnectionState, setHandheldConnectionState] =
    useState<HandheldConnectionStateEnum>();

  useEffect(() => {
    if (props.monitorMessage) {
      const abstractStatusPayload = props.monitorMessage
        .payload as AbstractStatusPayload;
      setStatusMonitorPayloadVersion(abstractStatusPayload.version);
      switch (abstractStatusPayload.version) {
        case STATUS_MONITOR_VERSION_0:
          const statusPayloadV0 = abstractStatusPayload as StatusPayloadV0;
          setPropertieStatusPayloadV0(statusPayloadV0);
          break;
        case STATUS_MONITOR_VERSION_1:
          const statusPaylodV1 = abstractStatusPayload as StatusPayloadV1;
          setPropertiesMonitorPayloadV1(statusPaylodV1);
          break;
        case STATUS_MONITOR_VERSION_2:
          const statusPaylodV2 = abstractStatusPayload as StatusPayloadV2;
          setPropertiesMonitorPayloadV2(statusPaylodV2);
          break;
      }
    }
  }, [props.monitorMessage]);

  const setPropertieStatusPayloadV0 = (statusPayloadV0: StatusPayloadV0) => {
    setActivatedSensors(statusPayloadV0.activatedSensors);
    setErrorCode(statusPayloadV0.errorCode);
    setHandheldLimitation(statusPayloadV0.handheldLimitation);
    setRssiZone(statusPayloadV0.hhZone);
    setHhButtons(statusPayloadV0.hhButtons);
    setOutputA(statusPayloadV0.outputA);
    setOutputB(statusPayloadV0.outputB);
    setPlatformAngle(statusPayloadV0.platformAngle);
    setHandheldSignal(statusPayloadV0.hhSignal);
    setWlanIndex(statusPayloadV0.wlanIndex);
    setBatteryLevel(statusPayloadV0.vehicleBatteryLevel);
    setAutotiltDown(statusPayloadV0.autoTiltDown);
    setCabinSwitch(statusPayloadV0.cabinSwitch);
  };

  const setPropertiesMonitorPayloadV1 = (statusPayloadV1: StatusPayloadV1) => {
    setPropertieStatusPayloadV0(statusPayloadV1);
    setInputInterface(statusPayloadV1.inputInterface);
    setSystemLimitation(statusPayloadV1.systemLimitation);
    setPlatformSensor(statusPayloadV1.platformSensor);
  };

  const setPropertiesMonitorPayloadV2 = (statusPayloadV2: StatusPayloadV2) => {
    // Different versions
    setPlatformSensor(statusPayloadV2.platformSensor);
    setActivatedSensors(statusPayloadV2.activatedSensors);

    setLock(statusPayloadV2.lock);
    setAnalogIo(statusPayloadV2.analogIo);
    setActiveCableControl(statusPayloadV2.monitorCableControl?.activeControl);
    setBlockedControls(statusPayloadV2.monitorCableControl?.blockedControls);
    setActiveFunctions(statusPayloadV2.monitorCableControl?.activeFunction);
    setBatteryIndicator(statusPayloadV2.handheld?.batteryIndicator);
    setHandheldLimitation(statusPayloadV2.handheld?.limitation);
    setHandheldConnectionState(statusPayloadV2.handheld?.connectionInfo);

    setRssiZone(statusPayloadV2.handheld?.zone);
    setHhButtons(statusPayloadV2.handheld?.buttons);
    setHandheldSignal(statusPayloadV2.handheld?.signalRssi);
    setPlatformAngle(statusPayloadV2.platformSensor?.platformAngle);
    setInputInterface(statusPayloadV2.inputInterface);
    setSystemLimitation(statusPayloadV2.systemLimitation);
    setErrorCode(statusPayloadV2.errorCode);
    setOutputA(statusPayloadV2.outputA);
    setOutputB(statusPayloadV2.outputB);
    setWlanIndex(statusPayloadV2.wlanIndex);
    setBatteryLevel(statusPayloadV2.vehicleBatteryLevel);
    setAutotiltDown(statusPayloadV2.autoTiltDown);
    setCabinSwitch(statusPayloadV2.cabinSwitch);
  };

  return (
    <>
      <DeviceStatusSystem
        errorCode={errorCode}
        handheldLimitation={handheldLimitation}
        systemLimitation={systemLimitation}
      />

      <DeviceStatusHandheld
        handheldLimitation={handheldLimitation}
        rssiZone={rssiZone}
        handheldButtons={hhButtons}
        handheldSignal={handheldSignal}
        batteryIndicator={batteryIndicator}
        handheldConnectionState={handheldConnectionState}
      />

      <DeviceStatusController
        statusMonitorPayloadVersion={statusMonitorPayloadVersion}
        inputInterface={inputInterface}
        outputA={outputA}
        outputB={outputB}
        wlanIndex={wlanIndex}
        batteryLevel={batteryLevel}
        activatedSensors={activatedSensors}
        autotiltDown={autotiltDown}
        cabinSwitch={cabinSwitch}
        lock={lock}
        analogIo={analogIo}
        activeCableControl={activeCableControl}
        activeFunctions={activeFunctions}
        blockedControls={blockedControls}
      />

      <DeviceStatusPlatformSensorContainer
        statusMonitorPayloadVersion={statusMonitorPayloadVersion}
        platformSensor={platformSensor}
        platformAngle={platformAngle}
        activatedSensors={activatedSensors}
      />
    </>
  );
};

export { DeviceStatus };
