import { StompSubscription } from "@stomp/stompjs";
import { useEffect } from "react";
import { messageCallbackType, useStompClient } from "react-stomp-hooks";

const useStompSubscription = (
  topic: string,
  callback: messageCallbackType,
  preventSubscribe: boolean = false
) => {
  const stompClient = useStompClient();

  useEffect(() => {
    if (preventSubscribe) return;

    let stompSubscription: StompSubscription | undefined = undefined;

    if (stompClient?.connected) {
      stompSubscription = stompClient.subscribe(topic, callback);
    }

    return () => {
      stompSubscription?.unsubscribe();
    };
  }, [stompClient?.connected]);
};

export { useStompSubscription };
