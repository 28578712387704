import { useSelector } from "react-redux";
import { RxPlsVersion } from "../cobra-backend-client";
import { RootState } from "../stores/store";

export const useGetDeviceVersion = (): RxPlsVersion | undefined => {
  const registeredDevices = useSelector(
    (state: RootState) => state.device.registeredDevices
  );
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  const connectedDevice = registeredDevices.find(
    (device) => device.deviceId === currentConnectedDeviceId
  );

  return connectedDevice?.version;
};
