import { Container, useMediaQuery } from "@mui/material";
import { DeviceConfigWrapper } from "../components/common/DeviceConfigurationWrapper";
import { DeviceConfigurationSettingsContainer } from "../components/device/configuration-settings/DeviceConfigurationSettingsContainer";
import { customizedTheme } from "../theme/theme";

interface Props {}

const DeviceConfigurationSettingsPage = (props: Props) => {
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );

  return (
    <DeviceConfigWrapper>
      {(deviceConfiguration) => (
        <Container
          maxWidth={isScreenSizeGreaterThanSm ? "sm" : false}
          disableGutters
        >
          <DeviceConfigurationSettingsContainer
            deviceConfiguration={deviceConfiguration}
          />
        </Container>
      )}
    </DeviceConfigWrapper>
  );
};

export { DeviceConfigurationSettingsPage };
