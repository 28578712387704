import { Alert, AlertTitle, LinearProgress } from "@mui/material";
import { IMessage, StompSubscription } from "@stomp/stompjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useStompClient } from "react-stomp-hooks";
import {
  AbstractMonitorMessage,
  MonitorPacketDto,
} from "../../../cobra-backend-client";
import { DEVICE_STATUS_INDEX } from "../../../constants/common";
import { TOPIC_MONITORING } from "../../../constants/topics";
import { RootState } from "../../../stores/store.js";
import { DeviceStatus } from "./DeviceStatus";

const MONITOR_MESSAGE_TIMEOUT = 4500;

const DeviceStatusContainer = () => {
  let monitorMessageTimeout: NodeJS.Timeout;

  const stompClient = useStompClient();

  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  const [monitorMessage, setMonitorMessage] =
    useState<AbstractMonitorMessage>();
  const [isMonitorMessageTimeout, setIsMonitorMessageTimeout] = useState(false);

  useEffect(() => {
    let stompSubscription: StompSubscription | undefined = undefined;

    if (stompClient?.connected) {
      stompSubscription = stompClient.subscribe(
        TOPIC_MONITORING,
        onMonitorMessage
      );
      startMonitorMessageTimeout();
    }

    return () => {
      stompSubscription?.unsubscribe();
      clearMonitorMessageTimeout();
    };
  }, [stompClient?.connected]);

  const onMonitorMessage = (message: IMessage) => {
    clearMonitorMessageTimeout();
    const monitorPacket = JSON.parse(message.body) as MonitorPacketDto;

    if (
      currentConnectedDeviceId == monitorPacket.deviceId &&
      monitorPacket.payloads &&
      monitorPacket.payloads.length > 0
    ) {
      const monitorMessageDto: AbstractMonitorMessage | undefined =
        monitorPacket.payloads.find(
          (monitorMessage) => monitorMessage.index === DEVICE_STATUS_INDEX
        );
      if (!monitorMessageDto) return;
      if (monitorMessageDto.index === DEVICE_STATUS_INDEX) {
        setMonitorMessage(monitorMessageDto);
      }
    }
  };

  const startMonitorMessageTimeout = () => {
    setIsMonitorMessageTimeout(false);
    if (monitorMessageTimeout) {
      clearTimeout(monitorMessageTimeout);
    }
    monitorMessageTimeout = setTimeout(
      handleMonitorTimeout,
      MONITOR_MESSAGE_TIMEOUT
    );
  };

  const handleMonitorTimeout = () => {
    if (!currentConnectedDeviceId) return;
    setIsMonitorMessageTimeout(true);
  };

  const clearMonitorMessageTimeout = () => {
    if (monitorMessageTimeout) {
      clearTimeout(monitorMessageTimeout);
    }
  };

  return (
    <>
      {monitorMessage != null ? (
        <DeviceStatus monitorMessage={monitorMessage} />
      ) : isMonitorMessageTimeout ? (
        <>
          <Alert severity="error">
            <AlertTitle>Device Status is unavailable</AlertTitle>
            Please update your device to the newest Software-Version to use all
            Functions of this application.
          </Alert>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export { DeviceStatusContainer };
