import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGIN_PAGE } from "../../constants/pages";
import { clearDeviceSlice } from "../../stores/slices/deviceSlice";
import { RootState } from "../../stores/store";

interface Props {
  disconnectionReasonText: string;
}

const DeviceOfflineDialog = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  return (
    <Dialog open={true} aria-modal="true">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" sx={{ mt: 3 }}>
          Device with id {currentConnectedDeviceId} is offline
        </Typography>
        <Typography sx={{ mb: 2, px: 3 }} variant="h6">
          {props.disconnectionReasonText}
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                navigate(LOGIN_PAGE);
                dispatch(clearDeviceSlice());
              }}
            >
              <PowerSettingsNewIcon />
            </Button>
          </Box>
        </Stack>

        <CircularProgress sx={{ my: 3 }} color="primary" size={70} />
      </Box>
    </Dialog>
  );
};

export { DeviceOfflineDialog };
