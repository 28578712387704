import { Chip, Grid, LinearProgress, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COBRA_API } from "../../../api/config";
import { setIsHandheldConnectionActive } from "../../../stores/slices/deviceSlice";
import { RootState } from "../../../stores/store";
import { CustomAlert } from "../../common/CustomAlert";

interface Props {}

const HandheldConnection = (props: Props) => {
  const dispatch = useDispatch();
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  const isOpenHandheldConnectionActive = useSelector(
    (state: RootState) => state.device.isHandheldConnectionActive
  );

  const [handheldConnectionErrorMsg, setHandheldConnectionErrorMsg] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!currentConnectedDeviceId) return;

    (async () => {
      try {
        const handheldConnectionDto = (
          await COBRA_API.HandheldSettings.getHandheldConnectionState(
            currentConnectedDeviceId
          )
        ).data;

        dispatch(setIsHandheldConnectionActive(!!handheldConnectionDto.active));
      } catch (e) {
        dispatch(setIsHandheldConnectionActive(false));
        console.error(e);
      }
    })();
  }, [currentConnectedDeviceId]);

  const onOpenHandheldConnectionSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isOpen = event.target.checked;

    (async () => {
      await changeHandhleConnectionState(isOpen);
    })();
  };

  const changeHandhleConnectionState = async (isOpen: boolean) => {
    if (!currentConnectedDeviceId) return;
    setLoading(true);

    try {
      const handheldConnectionDto = (
        await COBRA_API.HandheldSettings.changeHandheldConnectionState(
          currentConnectedDeviceId,
          isOpen
        )
      ).data;
      dispatch(setIsHandheldConnectionActive(!!handheldConnectionDto.active));
    } catch (e) {
      setHandheldConnectionErrorMsg(
        "An error occurred. Could not change handheld connection state"
      );
      dispatch(setIsHandheldConnectionActive(false));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        {loading && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Controller</Typography>
      </Grid>

      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Activate open handheld connection</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Chip
          sx={{ mr: 1, minWidth: 100 }}
          variant="outlined"
          label={isOpenHandheldConnectionActive ? "Active" : "Inactive"}
          color={isOpenHandheldConnectionActive ? "success" : "error"}
        />

        <Switch
          inputProps={{ "aria-label": "controlled" }}
          checked={isOpenHandheldConnectionActive}
          onChange={onOpenHandheldConnectionSwitchChange}
        />
      </Grid>
      <Grid item xs={12}>
        {!!handheldConnectionErrorMsg && (
          <CustomAlert
            autoHideDurationMs={10000}
            onAlertClose={() => setHandheldConnectionErrorMsg("")}
            alertSeverity={"error"}
            alertText={handheldConnectionErrorMsg}
          />
        )}
      </Grid>
    </>
  );
};

export { HandheldConnection };
