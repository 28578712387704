import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import {
  RssiZoneVehicleTypeEnum,
  getRssiZoneVehicleTypeEnumValues,
  mapRssiZoneVehicleTypeEnumValueToDisplayValue,
} from "../../../models/RssiZoneVehicleTypeEnum";
import { getPositionSensorConfiguration } from "../../../utils/getPositionSensorConfiguration";
import { CustomSelect, CustomSelectOption } from "../../common/CustomSelect";
import { PositionSensorZones } from "./PositionSensorZones";

const selectOptions: CustomSelectOption[] =
  getRssiZoneVehicleTypeEnumValues().map((value) => {
    return {
      value: value,
      label: mapRssiZoneVehicleTypeEnumValueToDisplayValue(
        value as RssiZoneVehicleTypeEnum
      ),
    };
  });

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  deviceConfiguration: DeviceConfiguration;
}

const PositionSensor = (props: Props) => {
  const [selectedValue, setSelectedValue] = useState<
    RssiZoneVehicleTypeEnum | undefined
  >(props.updatedDeviceConfiguration.rssiZone?.vehicleType);

  useEffect(() => {
    setSelectedValue(props.updatedDeviceConfiguration.rssiZone?.vehicleType);
  }, [props.updatedDeviceConfiguration.rssiZone?.vehicleType]);

  const handleChange = (newValue: number) => {
    const vehicleType = newValue as RssiZoneVehicleTypeEnum;
    setSelectedValue(vehicleType);

    const positionSensorConfiguration = getPositionSensorConfiguration(
      vehicleType,
      props.deviceConfiguration
    );

    const updatedConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      rssiZone: {
        ...props.updatedDeviceConfiguration.rssiZone,
        ...positionSensorConfiguration,
        vehicleType: vehicleType,
      },
    };
    props.setUpdatedDeiceConfiguration(updatedConfiguration);
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Position sensor
      </Typography>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid
          item
          xs={4}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
            pt: 0,
          }}
        >
          <Typography>Type of vehicle</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <CustomSelect
            selectedValue={selectedValue?.toString() ?? ""}
            options={selectOptions}
            onChange={handleChange}
            disabledValues={[RssiZoneVehicleTypeEnum.CUSTOM_CONFIGURATION]}
          />
        </Grid>
        <PositionSensorZones
          updatedDeviceConfiguration={props.updatedDeviceConfiguration}
          setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
        />
      </Grid>
    </>
  );
};

export { PositionSensor };
