import { useSelector } from "react-redux";
import {
  VERSION_MC_FIRMWARE,
  VERSION_SC_FIRMWARE,
} from "../../../constants/displayProperties";
import { useGetDeviceVersion } from "../../../hooks/useGetDeviceVersion";
import { RootState } from "../../../stores/store";
import {
  createPlsVersionDisplayPropertyValueMap,
  createRxVersionDisplayPropertyValueMap,
} from "../../../utils/deviceVersionUtils";
import { DeviceVersionLayout } from "./DeviceVersionLayout";

interface Props {}

const DeviceVersionComponent = (props: Props) => {
  const deviceVersion = useGetDeviceVersion();

  const cobraDeviceMcFwVersion = useSelector(
    (state: RootState) => state.firmwareVersion.cobraDeviceMcFwVersion
  );
  const cobraDeviceScFwVersion = useSelector(
    (state: RootState) => state.firmwareVersion.cobraDeviceScFwVersion
  );

  const cobraPlSensorMcFwVersion = useSelector(
    (state: RootState) => state.firmwareVersion.cobraPlSensorMcFwVersion
  );
  const cobraPlSensorScFwVersion = useSelector(
    (state: RootState) => state.firmwareVersion.cobraPlSensorScFwVersion
  );

  const rxVersionDisplayPropertyValueMap =
    createRxVersionDisplayPropertyValueMap(deviceVersion);
  const plsVersionDisplayPropertyValueMap =
    createPlsVersionDisplayPropertyValueMap(deviceVersion);

  return (
    <>
      {Array.from(rxVersionDisplayPropertyValueMap.entries()).map(
        ([property, value], index) => {
          return (
            <DeviceVersionLayout
              key={property + value}
              title={index === 0 ? "Cobra 390 Controller" : undefined}
              propertyName={property}
              propertyInstalledVersion={value}
              propertyNewVersion={
                property === VERSION_MC_FIRMWARE
                  ? cobraDeviceMcFwVersion
                  : property === VERSION_SC_FIRMWARE
                    ? cobraDeviceScFwVersion
                    : undefined
              }
            />
          );
        }
      )}

      {Array.from(plsVersionDisplayPropertyValueMap.entries()).map(
        ([property, value], index) => {
          return (
            <DeviceVersionLayout
              key={property + value}
              title={index === 0 ? "Cobra 390 Platform sensor" : undefined}
              propertyName={property}
              propertyInstalledVersion={value}
              propertyNewVersion={
                property === VERSION_MC_FIRMWARE
                  ? cobraPlSensorMcFwVersion
                  : property === VERSION_SC_FIRMWARE
                    ? cobraPlSensorScFwVersion
                    : undefined
              }
            />
          );
        }
      )}
    </>
  );
};

export { DeviceVersionComponent };
