import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Grid, Typography } from "@mui/material";
import { ActiveFunctionIconDisplay } from "../../common/ActiveFunctionIconDisplay";

interface Props {
  activeFunctions: boolean[];
}

const ActiveFunctionsComponent = (props: Props) => {
  const noActiveFunctions = (functions: boolean[]) => {
    return functions.every((element) => !element);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>In button</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {noActiveFunctions(props.activeFunctions) && <RemoveIcon />}
          <ActiveFunctionIconDisplay activeFunctions={props.activeFunctions} />
        </Box>
      </Grid>
    </>
  );
};

export { ActiveFunctionsComponent };
