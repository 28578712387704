import { CloudDownload } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Chip,
  ChipPropsColorOverrides,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { AxiosError, HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COBRA_API } from "../../../api/config";
import { VariokeyItem } from "../../../cobra-backend-client";
import { RootState } from "../../../stores/store";
import {
  findVariokeyByVariokeyIndex,
  retrieveVariokeySelectOptions,
  UNKNOWN_VARIOKEY_NAME,
} from "../../../utils/variokeyUtils";
import { ButtonWithTooltip } from "../../common/ButtonWithTooltip";
import { CustomAlert } from "../../common/CustomAlert";
import { CustomSelect, CustomSelectOption } from "../../common/CustomSelect";

interface Props {}

const NOT_CONNECTED_HANDHELD_VARIOKEY: VariokeyItem = {
  name: "Data Missing",
  index: -1,
};
const ERROR_MESSAGE_HANDHELD_NOT_CONNECTED =
  "Before you can edit the settings on your handheld, please conect your handheld by double-clicking on the Truck button near a poistion sensor.";

const HandheldSettings = (props: Props) => {
  const variokeys = useSelector(
    (state: RootState) => state.variokeys.variokeys
  );
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  const [selectOptions, setSelectOptions] = useState<CustomSelectOption[]>([]);
  const [selectedVariokeyItem, setSelectedVariokeyItem] =
    useState<VariokeyItem>(NOT_CONNECTED_HANDHELD_VARIOKEY);
  const [handheldVariokeyItem, setHandheldVariokeyItem] =
    useState<VariokeyItem>(NOT_CONNECTED_HANDHELD_VARIOKEY);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlertVariokeyUpdated, setShowAlertVariokeyUpdated] =
    useState<boolean>(false);

  const [isVariokeyChipUpdated, setIsVariokeyChipUpdated] =
    useState<boolean>(false);

  const [chipColor, setChipColor] =
    useState<
      OverridableStringUnion<
        | "default"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning",
        ChipPropsColorOverrides
      >
    >("default");

  useEffect(() => {
    const variokeysList = [
      ...retrieveVariokeySelectOptions([
        ...variokeys,
        NOT_CONNECTED_HANDHELD_VARIOKEY,
      ]),
    ];
    setSelectOptions(variokeysList);
  }, [variokeys]);

  useEffect(() => {
    if (isVariokeyChipUpdated) {
      setChipColor("success");

      const timer = setTimeout(() => {
        setChipColor("default");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isVariokeyChipUpdated]);

  const onVariokeyChange = (newValue: number) => {
    removeDataMissingVariokeyFromSelectOptions();

    if (selectedVariokeyItem.name === UNKNOWN_VARIOKEY_NAME) {
      setSelectOptions(retrieveVariokeySelectOptions([...variokeys]));
    }

    const variokey = variokeys.find(
      (variokey) => variokey.index === newValue
    ) as VariokeyItem;
    setSelectedVariokeyItem(variokey);
  };

  const getSelectDisabledValues = () => {
    var disabledSelectVariokey = selectOptions.find(
      (v) =>
        v.label === UNKNOWN_VARIOKEY_NAME ||
        v.label === NOT_CONNECTED_HANDHELD_VARIOKEY.name
    )?.value;
    if (!disabledSelectVariokey) {
      return [];
    }
    return [disabledSelectVariokey];
  };

  const getHandheldVariokey = async () => {
    if (!currentConnectedDeviceId) return;

    setLoading(true);
    setIsVariokeyChipUpdated(false);

    try {
      const variokeyIndex = (
        await COBRA_API.HandheldSettings.getHandheldVariokey(
          currentConnectedDeviceId
        )
      ).data;

      const variokey = findVariokeyByVariokeyIndex(variokeys, variokeyIndex);
      if (variokey.name === UNKNOWN_VARIOKEY_NAME) {
        setSelectOptions(
          retrieveVariokeySelectOptions([...variokeys, variokey])
        );
      }

      setErrorMsg("");
      setHandheldVariokeyItem(variokey);
      setSelectedVariokeyItem(variokey);
      setIsVariokeyChipUpdated(true);

      removeDataMissingVariokeyFromSelectOptions();
    } catch (error: any) {
      handleVariokeyError(error);
      setIsVariokeyChipUpdated(false);
    } finally {
      setLoading(false);
    }
  };

  const setHandheldVariokey = async () => {
    if (!currentConnectedDeviceId || selectedVariokeyItem.index == null) return;

    setLoading(true);
    setIsVariokeyChipUpdated(false);

    try {
      const variokeyIndex = (
        await COBRA_API.HandheldSettings.setHandheldVariokey(
          currentConnectedDeviceId,
          selectedVariokeyItem.index
        )
      ).data;

      const variokey = findVariokeyByVariokeyIndex(variokeys, variokeyIndex);
      if (variokey.name === UNKNOWN_VARIOKEY_NAME) {
        setSelectOptions(
          retrieveVariokeySelectOptions([...variokeys, variokey])
        );
      }

      setErrorMsg("");
      setHandheldVariokeyItem(variokey);
      setSelectedVariokeyItem(variokey);
      setIsVariokeyChipUpdated(true);
      setShowAlertVariokeyUpdated(true);

      removeDataMissingVariokeyFromSelectOptions();
    } catch (error: any) {
      handleVariokeyError(error);
      setIsVariokeyChipUpdated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleVariokeyError = (error: any) => {
    if (error instanceof AxiosError) {
      if (error.response?.status === HttpStatusCode.ServiceUnavailable) {
        const errorMessageTitle = error.response.data.title;
        const errorMessage = error.response.data.detail;
        if (errorMessageTitle === "HANDHELD_NOT_CONNECTED") {
          setErrorMsg(ERROR_MESSAGE_HANDHELD_NOT_CONNECTED);
        } else {
          setErrorMsg(errorMessage);
        }
      }
      if (error.response?.status === HttpStatusCode.RequestTimeout) {
        setErrorMsg("The request has timed out.");
      }
    } else {
      setErrorMsg("An unknown error has occurred.");
    }
  };

  const removeDataMissingVariokeyFromSelectOptions = () => {
    const isUnknownOrDataMissingInSelectOptions = selectOptions.some(
      (so) => so.label === NOT_CONNECTED_HANDHELD_VARIOKEY.name
    );
    if (isUnknownOrDataMissingInSelectOptions) {
      setSelectOptions([...retrieveVariokeySelectOptions(variokeys)]);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">Handheld</Typography>
      </Grid>

      <Grid
        item
        xs={3}
        sm={4}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Handheld Variokey</Typography>
      </Grid>

      <Grid
        item
        xs={4}
        sm={4}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Chip
          variant={chipColor == "success" ? "filled" : "outlined"}
          color={chipColor}
          label={handheldVariokeyItem.name}
          sx={{ width: "100%" }}
        />
      </Grid>

      <Grid
        item
        xs={5}
        sm={4}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <CustomSelect
          selectedValue={selectedVariokeyItem.index?.toString() ?? ""}
          options={selectOptions}
          disabledValues={getSelectDisabledValues()}
          onChange={onVariokeyChange}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={"center"}
          spacing={{ xs: 2, sm: 3, md: 4 }}
        >
          <ButtonWithTooltip
            startIcon={<CloudDownload />}
            buttonText="Read from handheld"
            variant="contained"
            tooltipText="Read the variokey from the handheld"
            onClick={getHandheldVariokey}
            disabled={loading}
          />

          <ButtonWithTooltip
            startIcon={<CloudUploadIcon />}
            buttonText="Save to handheld"
            variant="contained"
            tooltipText="Save the variokey to the handheld"
            onClick={setHandheldVariokey}
            disabled={
              loading ||
              selectedVariokeyItem.name === NOT_CONNECTED_HANDHELD_VARIOKEY.name
            }
          />
        </Stack>
      </Grid>

      <Grid item xs={12}>
        {loading && <LinearProgress />}
        {showAlertVariokeyUpdated && (
          <CustomAlert
            autoHideDurationMs={10000}
            onAlertClose={() => setShowAlertVariokeyUpdated(false)}
            alertSeverity={"success"}
            alertText="Variokey has been updated successfully"
          />
        )}
        {!!errorMsg && (
          <CustomAlert
            autoHideDurationMs={17000}
            onAlertClose={() => setErrorMsg("")}
            alertSeverity={"error"}
            alertText={errorMsg}
          />
        )}
      </Grid>
    </>
  );
};

export { HandheldSettings };
