export enum ActiveCableControlEnum {
  CABLE_CONTROL_OUTSIDE_RIGHT_ACTIVE = 0,
  CABLE_CONTROL_OUTSIDE_LEFT_ACTIVE = 1,
  CABLE_CONTROL_INSIDE_ACTIVE = 2,
  FOOT_SWITCH_ACTIVE = 3,
  ACTIVE_CABLE_CONTROL_LAST_ELEMENT = 4,
  NO_CABLE_CONTROL_ACTIVE = 255,
}

export const ActiveCableControlMessages: Record<
  ActiveCableControlEnum,
  string
> = {
  [ActiveCableControlEnum.CABLE_CONTROL_OUTSIDE_RIGHT_ACTIVE]: "outside right",
  [ActiveCableControlEnum.CABLE_CONTROL_OUTSIDE_LEFT_ACTIVE]: "outside left",
  [ActiveCableControlEnum.CABLE_CONTROL_INSIDE_ACTIVE]: "inside",
  [ActiveCableControlEnum.FOOT_SWITCH_ACTIVE]: "footswitch",
  [ActiveCableControlEnum.ACTIVE_CABLE_CONTROL_LAST_ELEMENT]: "last element",
  [ActiveCableControlEnum.NO_CABLE_CONTROL_ACTIVE]: "no",
};
