import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { COBRA_API } from "../../api/config";
import { DEVICE_STATUS_INDEX } from "../../constants/common";
import { setIsDeviceStatusMonitorStarted } from "../../stores/slices/deviceSlice";

export const enableDeviceStatusMonitor = async (
  deviceId: number,
  isDeviceStatusMonitorStarted: boolean,
  dispatch: Dispatch<UnknownAction>
) => {
  if (isDeviceStatusMonitorStarted) return;

  try {
    await COBRA_API.DeviceMonitor.enableMonitoring(
      DEVICE_STATUS_INDEX.toString(),
      deviceId
    );

    dispatch(setIsDeviceStatusMonitorStarted(true));
  } catch (e) {
    throw e;
  }
};

export const disableDeviceStatusMonitor = async (
  deviceId: number,
  dispatch: Dispatch<UnknownAction>
) => {
  try {
    await COBRA_API.DeviceMonitor.disableMonitoring(
      DEVICE_STATUS_INDEX.toString(),
      deviceId
    );

    dispatch(setIsDeviceStatusMonitorStarted(false));
  } catch (e) {
    throw e;
  }
};
