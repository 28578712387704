import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { STORAGE_JWT_KEY } from "../constants/browserStorage";
import { SIGN_IN_PAGE } from "../constants/pages";
import { clearDeviceSlice } from "../stores/slices/deviceSlice";
import { setUserToken } from "../stores/slices/userSlice";
import { RootState } from "../stores/store";
import { saveToStorage } from "../utils/browserStorage";
import { isJwtTokenExpired } from "../utils/jwt";

interface Props {
  children: React.ReactNode;
}
const PrivateOutlet = (props: Props) => {
  const userToken = useSelector((state: RootState) => state.user.token);
  const dispatch = useDispatch();

  if (!isJwtTokenExpired(userToken)) {
    return (
      <>
        {props.children}
        <Outlet />
      </>
    );
  } else {
    dispatch(clearDeviceSlice());
    dispatch(setUserToken(null));
    saveToStorage(localStorage, STORAGE_JWT_KEY, null);
    saveToStorage(sessionStorage, STORAGE_JWT_KEY, null);

    return <Navigate to={SIGN_IN_PAGE} replace />;
  }
};

export { PrivateOutlet };
