import { Container, useMediaQuery } from "@mui/material";
import { DeviceConfigWrapper } from "../components/common/DeviceConfigurationWrapper";
import { ControllerInstallationContainer } from "../components/device/configuration-installation/ControllerInstallationContainer";
import { customizedTheme } from "../theme/theme";

interface Props {}

const ControllerInstallationPage = (props: Props) => {
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );

  return (
    <Container
      maxWidth={isScreenSizeGreaterThanSm ? "sm" : false}
      disableGutters
    >
      <DeviceConfigWrapper>
        {(deviceConfiguration) => (
          <ControllerInstallationContainer
            deviceConfiguration={deviceConfiguration}
          />
        )}
      </DeviceConfigWrapper>
    </Container>
  );
};

export { ControllerInstallationPage };
