import { Grid, Typography } from "@mui/material";
import {
  AbstractActivatedSensors,
  CabinSwitch,
  InputInterface,
  InputInterfaceV2,
  Lock,
  MonitorAutoTiltDown,
  OutputA,
  OutputB,
} from "../../../cobra-backend-client";
import { ActiveCableControlEnum } from "../../../models/dto/ws/monitoring/ActiveCableControlEnum";
import { ActiveCableControlComponent } from "./ActiveCableControlComponent";
import { ActiveFunctionsComponent } from "./ActiveFunctionsComponent";
import { AnalogIoComponent } from "./AnalogIoComponent";
import { AutotiltDown } from "./AutotiltDown";
import { BatteryLevel } from "./BatteryLevel";
import { BlockedCableControlComponent } from "./BlockedCableControlComponent";
import { CabinSwitchComponent } from "./CabinSwitchComponent";
import { CableControlLocked } from "./CableControlLockedComponent";
import { InputInterfaceComponent } from "./InputInterfaceComponent";
import { OutOutput } from "./OutOutput";
import { WlanName } from "./WlanName";

interface Props {
  statusMonitorPayloadVersion: string | undefined;
  inputInterface: InputInterface | InputInterfaceV2 | undefined;
  outputA: OutputA | undefined;
  outputB: OutputB | undefined;
  wlanIndex: number | undefined;
  batteryLevel: number | undefined;
  autotiltDown: MonitorAutoTiltDown | undefined;
  activatedSensors: AbstractActivatedSensors | undefined;
  cabinSwitch: CabinSwitch | undefined;
  lock: Lock | undefined;
  analogIo: number | undefined;
  activeCableControl: ActiveCableControlEnum | undefined;
  activeFunctions: boolean[] | undefined;
  blockedControls: boolean[] | undefined;
}

const DeviceStatusController = (props: Props) => {
  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h4">Controller</Typography>
        <Grid container rowSpacing={1}>
          {props.inputInterface != null && (
            <InputInterfaceComponent
              inputInterface={props.inputInterface}
              activatedSensors={props.activatedSensors}
              statusMonitorPayloadVersion={props.statusMonitorPayloadVersion}
            />
          )}
          {props.activeCableControl != null && (
            <ActiveCableControlComponent
              activeCableControl={props.activeCableControl}
            />
          )}
          {props.activeFunctions != null && (
            <ActiveFunctionsComponent activeFunctions={props.activeFunctions} />
          )}
          {props.blockedControls != null && (
            <BlockedCableControlComponent
              blockedControls={props.blockedControls}
            />
          )}
          {props.outputA != null && props.outputB != null && (
            <OutOutput outputA={props.outputA} outputB={props.outputB} />
          )}

          {props.wlanIndex != null && <WlanName wlanIndex={props.wlanIndex} />}
          {props.batteryLevel != null && (
            <BatteryLevel batteryLevel={props.batteryLevel} />
          )}
          {props.autotiltDown != null &&
            props.activatedSensors?.autotiltDownSwitch != null && (
              <AutotiltDown
                autotiltDown={props.autotiltDown}
                autotiltDownSwitchSensor={
                  props.activatedSensors.autotiltDownSwitch
                }
              />
            )}
          {props.cabinSwitch != null &&
            props.activatedSensors?.cabinSwitch != null && (
              <CabinSwitchComponent
                cabinSwitch={props.cabinSwitch}
                cabinSwitchSensor={props.activatedSensors.cabinSwitch}
              />
            )}

          {props.lock != null && <CableControlLocked lock={props.lock} />}
          {props.analogIo != null && (
            <AnalogIoComponent analogIo={props.analogIo} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { DeviceStatusController };
