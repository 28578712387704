import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { COBRA_API } from "../../api/config";
import {
  CONTROLLER_INSTALLATION_PAGE,
  DEVICE_STATUS_PAGE,
  LOGIN_PAGE,
} from "../../constants/pages";
import { useIsDeviceOnline } from "../../hooks/useIsDeviceOnline";
import { useIsDeviceRestartScheduled } from "../../hooks/useIsDeviceRestartScheduled";
import { onlineDeviceDrawerMenuItems } from "../../routes/onlineDeviceDrawerMenuItems";
import {
  clearDeviceSlice,
  setIsHandheldConnectionActive,
} from "../../stores/slices/deviceSlice";
import { setDrawerOpen } from "../../stores/slices/drawerSlice";
import { RootState } from "../../stores/store";
import { customizedTheme, drawerWidth } from "../../theme/theme";
import { isJwtTokenExpired } from "../../utils/jwt";
import { disableDeviceStatusMonitor } from "../common/deviceStatusMonitor";
import { IconButtonWithTooltip } from "../common/IconButtonWithTooltip";
import { AppDrawerMenuItem } from "./AppDrawerMenuItem";
import { AppDrawerOnlineDeviceOrAuthenticatedMenuItem } from "./AppDrawerOnlineDeviceOrAuthenticatedMenuItem";
import { DrawerHeader } from "./DrawerHeader";

const AppDrawer = () => {
  const isSmallScreen = useMediaQuery(customizedTheme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const open = useSelector((state: RootState) => state.drawer.open);
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );
  const userToken = useSelector((state: RootState) => state.user.token);

  const isDeviceOnline = useIsDeviceOnline();
  const isDeviceRestartScheduled = useIsDeviceRestartScheduled();

  const stopDevieStatusMonitor = async () => {
    if (!currentConnectedDeviceId || !location) return;
    if (
      location.pathname === DEVICE_STATUS_PAGE ||
      location.pathname === CONTROLLER_INSTALLATION_PAGE
    ) {
      try {
        disableDeviceStatusMonitor(currentConnectedDeviceId, dispatch);
      } catch (error) {}
    }
  };

  const setHandheldConnectionInactive = async () => {
    if (!currentConnectedDeviceId) return;
    try {
      const handheldConnectionDto = (
        await COBRA_API.HandheldSettings.changeHandheldConnectionState(
          currentConnectedDeviceId,
          false
        )
      ).data;

      dispatch(setIsHandheldConnectionActive(!!handheldConnectionDto.active));
    } catch (error) {}
  };

  const onDisconnectFromTheDeviceClick = () => {
    if (userToken && !isJwtTokenExpired(userToken)) {
      setHandheldConnectionInactive();
    }

    stopDevieStatusMonitor();
    navigate(LOGIN_PAGE);
    dispatch(clearDeviceSlice());
  };

  return (
    <Box component="nav" sx={{ width: 0, flexShrink: 0 }}>
      <Drawer
        sx={{
          width: `${drawerWidth}px`,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: `${drawerWidth}px`,
            boxSizing: "border-box",
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        variant={isSmallScreen ? "temporary" : "persistent"}
        // This works only for temporary drawer!
        onClose={(_, reason) =>
          reason === "backdropClick" && dispatch(setDrawerOpen(false))
        }
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Grid container spacing={0} alignItems="center">
            {currentConnectedDeviceId != null ? (
              <Grid item xs={8}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography> Device {currentConnectedDeviceId}</Typography>
                  <Chip
                    sx={{ ml: 1 }}
                    label={
                      (isDeviceOnline && isDeviceRestartScheduled) ||
                      !isDeviceOnline
                        ? "offline"
                        : "online"
                    }
                    variant="outlined"
                    color={
                      (isDeviceOnline && isDeviceRestartScheduled) ||
                      !isDeviceOnline
                        ? "error"
                        : "success"
                    }
                    size="small"
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={10}>
                <Typography>No connected device</Typography>
              </Grid>
            )}

            {currentConnectedDeviceId != null && (
              <Grid item xs={2}>
                <IconButtonWithTooltip
                  icon={<PowerSettingsNewIcon />}
                  color="error"
                  tooltipText="Disconnect from device"
                  onClick={onDisconnectFromTheDeviceClick}
                />
              </Grid>
            )}

            <Grid item xs={2}>
              <IconButton onClick={() => dispatch(setDrawerOpen(false))}>
                {customizedTheme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        <List>
          {currentConnectedDeviceId != null && (
            <>
              {onlineDeviceDrawerMenuItems.map((item, index) => (
                <AppDrawerMenuItem key={index} item={item} />
              ))}
            </>
          )}
          <AppDrawerOnlineDeviceOrAuthenticatedMenuItem />
        </List>
      </Drawer>
    </Box>
  );
};

export { AppDrawer };
