import { CloudDownload } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { AxiosError, HttpStatusCode } from "axios";
import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { COBRA_API } from "../../../api/config";
import {
  DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum,
  SignedConfigurationAbstractConfiguration,
  SignedConfigurationDeviceConfiguration,
  SignedConfigurationDeviceTransmitterMemoryConfiguration,
} from "../../../cobra-backend-client";
import { DeviceConfigurationUpdateResponse } from "../../../models/DeviceConfigurationUpdateResponse";
import { RootState } from "../../../stores/store";
import { downloadFile, readFileContet } from "../../../utils/file";
import { ButtonWithTooltip } from "../../common/ButtonWithTooltip";
import { CustomAlert } from "../../common/CustomAlert";
import { CustomFileUploader } from "../../common/CustomFileUploader";

interface Props {
  // true DeviceConfiguration; false DeviceTransmitterMemoryConfiguration
  isDeviceConfiguration: boolean;
  configurationVersion: string | undefined;
}

const GenericConfiguration = (props: Props) => {
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );
  const isSchmidigerUser = useSelector(
    (state: RootState) => state.user.isSchmidigerUser
  );
  const [configurationFileVersion, setConfiguratioFileVersion] = useState<
    string | undefined
  >();
  const [
    showAlertIsConfigurationFileValid,
    setShowAlertIsConfigurationFileValid,
  ] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [
    unprocessableConfigurationErrorMsg,
    setUnprocessableConfigurationErrorMsg,
  ] = useState<string>("");
  const [modifiedConfigurationErrorMsg, setModifiedConfigurationErrorMsg] =
    useState<string>("");
  const [unknownErrorMsg, setUnknownErrorMsg] = useState<string>("");

  const [isConfigurationUpdateStarted, setIsConfigurationUpdateStarted] =
    useState(false);
  const [
    isConfigurationUpdatedSuccessfully,
    setIsConfigurationUpdatedSuccessfully,
  ] = useState<boolean>(false);
  const [configurationUpdateMsg, setConfigurationUpdateMsg] =
    useState<string>();
  const [showAlertConfigurationUpdate, setShowAlertConfigurationUpdate] =
    useState(false);

  const [downloadConfigurationError, setDownloadConfigurationError] =
    useState<string>("");

  const downloadConfiguration = async () => {
    setDownloadConfigurationError("");
    if (props.isDeviceConfiguration) {
      downloadDeivceConfiguration();
    } else {
      downloadTransmitterConfiguration();
    }
  };

  const downloadTransmitterConfiguration = async () => {
    if (!currentConnectedDeviceId) return;
    try {
      const deviceConfigurationFile = (
        await COBRA_API.DeviceConfiguration.downloadSignedDeviceTransmitterMemoryConfigurationFile(
          currentConnectedDeviceId,
          { responseType: "blob" }
        )
      ).data;
      await downloadFile(
        deviceConfigurationFile,
        `device_${currentConnectedDeviceId}_bonded_handheld_serial_numbers_configuration.json`
      );
    } catch (e: any) {
      setDownloadConfigurationError(
        "Device Bonded Handheld Configuration can not be exported"
      );
    }
  };

  const downloadDeivceConfiguration = async () => {
    if (!currentConnectedDeviceId) return;
    try {
      const deviceTransmitterMemoryConfigurationFile = (
        await COBRA_API.DeviceConfiguration.downloadSignedDeviceConfigurationFile(
          currentConnectedDeviceId,
          { responseType: "blob" }
        )
      ).data;

      await downloadFile(
        deviceTransmitterMemoryConfigurationFile,
        `device_${currentConnectedDeviceId}_configuration.json`
      );
    } catch (e: any) {
      setDownloadConfigurationError("Device Configuration can not be exported");
    }
  };

  const handleFileValidate = async (file: File) => {
    if (!currentConnectedDeviceId) return;
    setShowAlertIsConfigurationFileValid(false);
    clearErrorMessesages();
    handleValidateDeviceConfigurationFile(file);
  };

  const handleValidateDeviceConfigurationFile = async (file: File) => {
    try {
      const signedConfiguration = (await readFileContet(
        file
      )) as SignedConfigurationAbstractConfiguration;

      const abstactConfiguration = (
        await COBRA_API.DeviceConfiguration.validateSignedConfiguration(
          signedConfiguration
        )
      ).data;

      setConfiguratioFileVersion(abstactConfiguration.configuration?.version);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.UnprocessableEntity) {
          parseUnprocessableEntityError(error);
          return;
        }
        if (error.response?.status === HttpStatusCode.BadRequest) {
          setModifiedConfigurationErrorMsg(
            "The configuration file has been modified and is no longer digitally signed."
          );
          return;
        }
      }
      setUnknownErrorMsg("An unknown error has occurred.");
    } finally {
      setShowAlertIsConfigurationFileValid(true);
    }
  };

  const parseUnprocessableEntityError = (error: any) => {
    const errorMessage = error.response.data.detail;
    const regex = /'([^']+)'/;
    const regexResultArray = errorMessage.match(regex);
    if (regexResultArray && regexResultArray.length > 0) {
      setUnprocessableConfigurationErrorMsg(
        `The JSON file format is incorrect. A required property ${regexResultArray[0]} is either missing or invalid.`
      );
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);

      (async () => await handleFileValidate(file))();
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(undefined);
    clearErrorMessesages();
    setShowAlertIsConfigurationFileValid(false);
  };

  const clearErrorMessesages = () => {
    setUnknownErrorMsg("");
    setModifiedConfigurationErrorMsg("");
    setUnprocessableConfigurationErrorMsg("");
  };

  const handleUpload = async () => {
    if (props.isDeviceConfiguration) {
      handleDeviceConfigurationUpload();
    } else {
      handleDeviceTransmitterMemoryConfigurationUpload();
    }
  };

  const handleDeviceTransmitterMemoryConfigurationUpload = async () => {
    if (currentConnectedDeviceId && selectedFile) {
      try {
        setIsConfigurationUpdateStarted(true);
        const signedConfigurationDeviceConfiguration = (await readFileContet(
          selectedFile
        )) as SignedConfigurationDeviceTransmitterMemoryConfiguration;
        const deviceConfigurationUpdateResponse = (
          await COBRA_API.DeviceConfiguration.updateSignedDeviceTransmitterMemoryConfiguration(
            currentConnectedDeviceId,
            signedConfigurationDeviceConfiguration
          )
        ).data;

        if (
          deviceConfigurationUpdateResponse.deviceConfigurationUpdateResponse ===
          DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum.NoErr
        ) {
          setIsConfigurationUpdatedSuccessfully(true);
        } else {
          setIsConfigurationUpdatedSuccessfully(false);
        }
        setConfigurationUpdateMsg(
          deviceConfigurationUpdateResponse.deviceConfigurationUpdateResponse
        );
      } catch (error: any) {
        setIsConfigurationUpdatedSuccessfully(false);
        setConfigurationUpdateMsg(
          DeviceConfigurationUpdateResponse.CUSTOM_ERROR_INVALID_JSON
        );
      } finally {
        setIsConfigurationUpdateStarted(false);
        setShowAlertConfigurationUpdate(true);
      }
    }
  };

  const handleDeviceConfigurationUpload = async () => {
    if (currentConnectedDeviceId && selectedFile) {
      try {
        setIsConfigurationUpdateStarted(true);
        const signedConfigurationDeviceConfiguration = (await readFileContet(
          selectedFile
        )) as SignedConfigurationDeviceConfiguration;
        const deviceConfigurationUpdateResponse = (
          await COBRA_API.DeviceConfiguration.updateSignedDeviceConfiguration(
            currentConnectedDeviceId,
            signedConfigurationDeviceConfiguration
          )
        ).data;

        if (
          deviceConfigurationUpdateResponse.deviceConfigurationUpdateResponse ===
          DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum.NoErr
        ) {
          setIsConfigurationUpdatedSuccessfully(true);
        } else {
          setIsConfigurationUpdatedSuccessfully(false);
        }
        setConfigurationUpdateMsg(
          deviceConfigurationUpdateResponse.deviceConfigurationUpdateResponse
        );
        setShowAlertConfigurationUpdate(true);
      } catch (error: any) {
        setIsConfigurationUpdatedSuccessfully(false);
        setConfigurationUpdateMsg(
          DeviceConfigurationUpdateResponse.CUSTOM_ERROR_INVALID_JSON
        );
      } finally {
        setIsConfigurationUpdateStarted(false);
        setShowAlertConfigurationUpdate(true);
      }
    }
  };

  const isConfigurationFileValid =
    unknownErrorMsg === "" &&
    unprocessableConfigurationErrorMsg === "" &&
    modifiedConfigurationErrorMsg === "";

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={0}
      >
        <Typography variant="h5">
          {props.isDeviceConfiguration
            ? "Configuration file"
            : "Bonded handheld serial numbers"}
        </Typography>
        <Typography variant="h6">
          {`Version: ${
            props.configurationVersion ? props.configurationVersion : "unknown"
          }`}
        </Typography>
        <ButtonWithTooltip
          startIcon={<CloudDownload />}
          buttonText="Download Configuration"
          variant="contained"
          tooltipText="Download configuration as json file"
          onClick={downloadConfiguration}
          sx={{ my: 2 }}
        />

        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">
              {props.isDeviceConfiguration
                ? "Configuration file Upload"
                : "Bonded handheld serial numbers Upload"}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <CustomFileUploader
              selectedFile={selectedFile}
              handleFileChange={handleFileChange}
              handleDeleteFile={handleDeleteFile}
              buttonText="Select File"
              buttonTooltipText="Select json configuration file"
              fileFormat=".json"
            />
          </Grid>
        </Grid>

        {selectedFile && (
          <Typography variant="h6">
            {"File version: "}
            {configurationFileVersion ? configurationFileVersion : "unknown"}
          </Typography>
        )}

        <ButtonWithTooltip
          startIcon={<CloudUploadIcon />}
          buttonText="Upload Configuration"
          variant="contained"
          tooltipText="Upload Configuration with file"
          onClick={handleUpload}
          disabled={!selectedFile || !isConfigurationFileValid}
          sx={{ my: 1 }}
        />

        {isSchmidigerUser &&
          selectedFile &&
          modifiedConfigurationErrorMsg !== "" && (
            <ButtonWithTooltip
              startIcon={<CloudUploadIcon />}
              buttonText="Upload configuration with unsigned file"
              variant="contained"
              tooltipText="Upload Configuration with unsigned file"
              onClick={handleUpload}
              disabled={!selectedFile}
              color="warning"
              sx={{ my: 1 }}
            />
          )}
      </Stack>

      {isConfigurationUpdateStarted && <LinearProgress sx={{ my: 2 }} />}

      {showAlertIsConfigurationFileValid && (
        <CustomAlert
          autoHideDurationMs={15000}
          onAlertClose={() => setShowAlertIsConfigurationFileValid(false)}
          alertSeverity={isConfigurationFileValid ? "success" : "error"}
          alertText={
            isConfigurationFileValid
              ? "Valid Configuration"
              : `${modifiedConfigurationErrorMsg} ${unprocessableConfigurationErrorMsg} ${unknownErrorMsg}`
          }
        />
      )}
      {showAlertConfigurationUpdate && (
        <CustomAlert
          autoHideDurationMs={8000}
          onAlertClose={() => setShowAlertConfigurationUpdate(false)}
          alertSeverity={
            isConfigurationUpdatedSuccessfully ? "success" : "error"
          }
          alertText={
            isConfigurationUpdatedSuccessfully
              ? `Successfully updated Configuration: ${configurationUpdateMsg}`
              : `Configuration can not be update ${configurationUpdateMsg}`
          }
        />
      )}

      {downloadConfigurationError !== "" && (
        <CustomAlert
          autoHideDurationMs={8000}
          onAlertClose={() => setShowAlertConfigurationUpdate(false)}
          alertSeverity={"error"}
          alertText={downloadConfigurationError}
        />
      )}
    </>
  );
};

export { GenericConfiguration };
