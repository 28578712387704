import { Chip, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  PlatformSensorV0V1,
  PlatformSensorV2,
} from "../../../cobra-backend-client";
import { STATUS_MONITOR_VERSION_2 } from "../../../constants/common";

interface Props {
  platformSensor: PlatformSensorV0V1 | PlatformSensorV2;
  statusMonitorPayloadVersion: string | undefined;
}
const PlatformSensorBlinkOutputComponent = (props: Props) => {
  const [platformSensorV2, setPlatformSensorV2] = useState<PlatformSensorV2>();
  const [blinkLabel, setBlinkLabel] = useState<string>("");

  useEffect(() => {
    if (props.statusMonitorPayloadVersion === STATUS_MONITOR_VERSION_2) {
      setPlatformSensorV2(props.platformSensor as PlatformSensorV2);
    } else {
      setPlatformSensorV2(undefined);
    }
  }, [props.platformSensor, props.statusMonitorPayloadVersion]);

  useEffect(() => {
    if (
      platformSensorV2?.outBlinkOffTime == null ||
      platformSensorV2?.outBlinkOnTime == null
    ) {
      return;
    }

    if (platformSensorV2.outBlinkOnTime === 0) {
      setBlinkLabel("off");
    } else if (
      platformSensorV2.outBlinkOnTime > 0 &&
      platformSensorV2.outBlinkOffTime === 0
    ) {
      setBlinkLabel("on");
    } else if (
      platformSensorV2.outBlinkOnTime > 0 &&
      platformSensorV2.outBlinkOffTime > 0
    ) {
      setBlinkLabel("blink");
    }
  }, [platformSensorV2?.outBlinkOffTime, platformSensorV2?.outBlinkOnTime]);

  return (
    <>
      <>
        <Grid
          item
          xs={6}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
          }}
        >
          <Typography>Blink output </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Chip label={blinkLabel} variant="outlined" />
        </Grid>
      </>
    </>
  );
};

export { PlatformSensorBlinkOutputComponent };
