import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Device } from "../../cobra-backend-client";

interface DeviceState {
  isDeviceStatusMonitorStarted: boolean;
  deviceRestartInProgress: boolean;
  registeredDevices: Device[];
  currentConnectedDeviceId: number | undefined;
  isHandheldConnectionActive: boolean;
}

const initialState: DeviceState = {
  isDeviceStatusMonitorStarted: false,
  deviceRestartInProgress: false,
  registeredDevices: [],
  currentConnectedDeviceId: undefined,
  isHandheldConnectionActive: false,
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setIsDeviceStatusMonitorStarted: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeviceStatusMonitorStarted = action.payload;
    },

    setDeviceRestartInProgress: (state, action: PayloadAction<boolean>) => {
      state.isDeviceStatusMonitorStarted = action.payload;
    },

    setRegisteredDevices: (state, action: PayloadAction<Device[]>) => {
      state.registeredDevices = action.payload;
    },
    setCurrentConnectedDeviceId: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.currentConnectedDeviceId = action.payload;
    },
    setIsHandheldConnectionActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isHandheldConnectionActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDeviceSlice, (state) => {
      state.currentConnectedDeviceId = initialState.currentConnectedDeviceId;
      state.isDeviceStatusMonitorStarted =
        initialState.isDeviceStatusMonitorStarted;
      state.deviceRestartInProgress = initialState.deviceRestartInProgress;
    });
  },
});

export const clearDeviceSlice = createAction("clearDeviceSlice");

export const {
  setIsDeviceStatusMonitorStarted,
  setDeviceRestartInProgress,
  setRegisteredDevices,
  setCurrentConnectedDeviceId,
  setIsHandheldConnectionActive,
} = deviceSlice.actions;
export default deviceSlice.reducer;
