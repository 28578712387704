import LockIcon from "@mui/icons-material/Lock";
import { Box, Grid, Typography } from "@mui/material";
import { Lock } from "../../../cobra-backend-client";
import { ReadOnlyCheckBox } from "../../common/ReadOnlyCheckBox";

interface Props {
  lock: Lock;
}

const CableControlLocked = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={4}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Cablecontrol locked</Typography>
        <Box>
          <LockIcon sx={{ mx: 1 }} />
        </Box>
      </Grid>
      <Grid
        item
        xs={8}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            component="span"
            sx={{ pr: 1.5, display: "flex", alignItems: "center" }}
          >
            auto lock
            <ReadOnlyCheckBox checked={props.lock.autoLock ?? false} />
          </Box>
          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            manual lock
            <ReadOnlyCheckBox checked={props.lock.manualLock ?? false} />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export { CableControlLocked };
