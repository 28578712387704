import { Box, Grid, Typography } from "@mui/material";
import { ReadOnlyCheckBox } from "../../common/ReadOnlyCheckBox";
import {
  AbstractActivatedSensors,
  ActivatedSensorsV2,
  InputInterface,
  InputInterfaceV2,
} from "../../../cobra-backend-client";
import { useEffect, useState } from "react";
import { mapBooleanArraysWithOr } from "../../../utils/mapBooleanArraysWithOr";
import {
  STATUS_MONITOR_VERSION_0,
  STATUS_MONITOR_VERSION_1,
  STATUS_MONITOR_VERSION_2,
} from "../../../constants/common";

const LAST_INPUT_INDEX = 4;

interface Props {
  statusMonitorPayloadVersion: string | undefined;
  inputInterface: InputInterface | InputInterfaceV2;
  activatedSensors: AbstractActivatedSensors | undefined;
}

const InputInterfaceComponent = (props: Props) => {
  const [activeInputs, setActiveInputs] = useState<boolean[]>();
  const [inputInterfaceEnabled, setInputInterfaceEnabled] = useState<boolean>();

  useEffect(() => {
    try {
      if (
        props.inputInterface.singleClick &&
        props.inputInterface.singleClick
      ) {
        const activeInputsArray = mapBooleanArraysWithOr(
          props.inputInterface.singleClick,
          props.inputInterface.singleClick
        );
        setActiveInputs(activeInputsArray);
      }
    } catch (e) {}
  }, [props.inputInterface]);

  useEffect(() => {
    if (props.statusMonitorPayloadVersion === STATUS_MONITOR_VERSION_2) {
      const activatedSensorsV2 = props.activatedSensors as ActivatedSensorsV2;
      setInputInterfaceEnabled(activatedSensorsV2.inputInterface);
    } else if (
      props.statusMonitorPayloadVersion === STATUS_MONITOR_VERSION_1 ||
      props.statusMonitorPayloadVersion === STATUS_MONITOR_VERSION_0
    ) {
      const inputInterfaceV0V1 = props.inputInterface as InputInterface;
      setInputInterfaceEnabled(inputInterfaceV0V1.enabled);
    }
  }, [props.statusMonitorPayloadVersion, props.inputInterface]);

  return (
    <>
      {inputInterfaceEnabled && (
        <>
          <Grid
            item
            xs={2}
            sm={4}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <Typography>In 1..5 </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            sm={8}
            sx={{
              alignItems: "center",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            {activeInputs?.map((input, index) => {
              const isChekcboxHidden = index > LAST_INPUT_INDEX;
              const isIndexMiddleOfTheArray =
                index === activeInputs.length / 2 - 1;
              return (
                <Box
                  key={`input_interface-${index}`}
                  component="span"
                  sx={{
                    pr: isIndexMiddleOfTheArray ? 1.5 : 0,
                    visibility: isChekcboxHidden ? "hidden" : "visible",
                  }}
                >
                  <ReadOnlyCheckBox checked={input} />
                </Box>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export { InputInterfaceComponent };
