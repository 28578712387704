import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as AlertSvg } from "../../../assets/Achtung.svg";
import { ReactComponent as NoOverPressure } from "../../../assets/noUeberdruck.svg";
import { ReactComponent as NoOverPressureGray } from "../../../assets/noUeberdruck_Grau.svg";
import {
  HandheldLimitation,
  SystemLimitation,
} from "../../../cobra-backend-client";

interface Props {
  handheldLimitation: HandheldLimitation | undefined;
  systemLimitation: SystemLimitation | undefined;
}

const SystemLimitationComponent = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>System limitation </Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {props.handheldLimitation?.limitedOperation ? (
            <AlertSvg
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          ) : (
            <WarningAmberIcon
              style={{ color: "#C6C6C6", width: "27px", height: "27px" }}
            />
          )}
          {props.systemLimitation?.motor ? (
            <NoOverPressure
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          ) : (
            <NoOverPressureGray
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          )}
        </Stack>
      </Grid>
    </>
  );
};

export { SystemLimitationComponent };
