import { Grid, Typography } from "@mui/material";
import { HandheldLimitation } from "../../../cobra-backend-client";
import { HandheldConnectionStateEnum } from "../../../models/dto/ws/monitoring/HandheldConnectionStateEnum";
import { RssiZoneEnum } from "../../../models/dto/ws/monitoring/RssiZoneEnum";
import { HandheldBattery } from "./HandheldBattery";
import { HandheldButton } from "./HandheldButton";
import { HandheldLimitationComponent } from "./HandheldLimitationComponent";
import { HandheldSignal } from "./HandheldSignal";
import { HandheldZone } from "./HandheldZone";
import { HandheldConnectionStateComponent } from "./HandheldConnectionStateComponent";

interface Props {
  handheldLimitation: HandheldLimitation | undefined;
  rssiZone: RssiZoneEnum | undefined;
  handheldButtons: boolean[] | undefined;
  handheldSignal: number | undefined;
  batteryIndicator: number | undefined;
  handheldConnectionState: HandheldConnectionStateEnum | undefined;
}

const DeviceStatusHandheld = (props: Props) => {
  return (
    <Grid container sx={{ mb: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h4">Handheld</Typography>
        <Grid container rowSpacing={1}>
          {props.handheldConnectionState != null && (
            <HandheldConnectionStateComponent
              handheldConnectionState={props.handheldConnectionState}
            />
          )}
          {props.handheldLimitation != null && (
            <HandheldLimitationComponent
              handheldLimitation={props.handheldLimitation}
            />
          )}
          {props.rssiZone != null && <HandheldZone rssiZone={props.rssiZone} />}
          {props.handheldButtons != null && (
            <HandheldButton handheldButtons={props.handheldButtons} />
          )}

          {props.handheldSignal != null && (
            <HandheldSignal handheldSignal={props.handheldSignal} />
          )}
          {props.batteryIndicator != null && (
            <HandheldBattery batteryIndicator={props.batteryIndicator} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { DeviceStatusHandheld };
