export enum MonitorErrorCodeEnum {
  NO_ERROR = 0,
  IO_MONITOR_A_ERR_REG = 1,
  IO_MONITOR_B_ERR_REG = 2,
  IO_MONITOR_A_SHUTDOWN = 3,
  IO_MONITOR_B_SHUTDOWN = 4,
  OVER_CURRENT_A = 5,
  OVER_CURRENT_B = 6,
  MCUCOM = 7,
  PLAUSIBILITYCHECK = 8,
  MAIN_STATE = 9,
  HS_STOP = 10,
  LEARNMODE = 11,
  IMU = 12,
  INPUT_INTERFACE = 13,
}

export const MonitorErrorCodeMessages: Record<MonitorErrorCodeEnum, string> = {
  [MonitorErrorCodeEnum.NO_ERROR]: "OK",
  [MonitorErrorCodeEnum.IO_MONITOR_A_ERR_REG]: "Error IO monitor A",
  [MonitorErrorCodeEnum.IO_MONITOR_B_ERR_REG]: "Error IO monitor B",
  [MonitorErrorCodeEnum.IO_MONITOR_A_SHUTDOWN]: "Error IO monitor A",
  [MonitorErrorCodeEnum.IO_MONITOR_B_SHUTDOWN]: "Error IO monitor B",
  [MonitorErrorCodeEnum.OVER_CURRENT_A]: "Error overcurrent A",
  [MonitorErrorCodeEnum.OVER_CURRENT_B]: "Error overcurrent B",
  [MonitorErrorCodeEnum.MCUCOM]: "Error internal communication",
  [MonitorErrorCodeEnum.PLAUSIBILITYCHECK]: "Error plausibility check",
  [MonitorErrorCodeEnum.MAIN_STATE]: "Error main state",
  [MonitorErrorCodeEnum.HS_STOP]: "Error handheld",
  [MonitorErrorCodeEnum.LEARNMODE]: "Error learn mode",
  [MonitorErrorCodeEnum.IMU]: "Error Platform sensor",
  [MonitorErrorCodeEnum.INPUT_INTERFACE]: "Error Input Interface",
};
