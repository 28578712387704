import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COBRA_API } from "../api/config";
import {
  DeviceConfiguration,
  DeviceTransmitterMemoryConfiguration,
} from "../cobra-backend-client";
import { GenericConfiguration } from "../components/device/configuration/GenericDeviceConfiguration";
import { RootState } from "../stores/store";

const DeviceConfigurationPage = () => {
  const currentConnectedDeviceId = useSelector((state: RootState) => state.device.currentConnectedDeviceId);

  const [deviceConfiguration, setDeviceConfiguration] =
    useState<DeviceConfiguration>();
  const [
    deviceTransmitterMemoryConfiguration,
    setDeviceTransmitterMemoryConfiguration,
  ] = useState<DeviceTransmitterMemoryConfiguration>();

  useEffect(() => {
    if (!currentConnectedDeviceId) return;

    getDeviceConfig(currentConnectedDeviceId);
    getDeviceTransmitterMemoryConfig(currentConnectedDeviceId);
  }, [currentConnectedDeviceId]);

  const getDeviceConfig = async (deviceId: number) => {
    try {
      const signedDeviceConfiguration = (
        await COBRA_API.DeviceConfiguration.getSignedDeviceConfiguration(
          deviceId
        )
      ).data;
      setDeviceConfiguration(signedDeviceConfiguration.configuration);
    } catch (e) {}
  };

  const getDeviceTransmitterMemoryConfig = async (deviceId: number) => {
    try {
      const signedDeviceTransmitterMemoryConfiguration = (
        await await COBRA_API.DeviceConfiguration.getSignedDeviceTransmitterMemoryConfiguration(
          deviceId
        )
      ).data;
      setDeviceTransmitterMemoryConfiguration(
        signedDeviceTransmitterMemoryConfiguration.configuration
      );
    } catch (e) {}
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GenericConfiguration
            isDeviceConfiguration={true}
            configurationVersion={deviceConfiguration?.version}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GenericConfiguration
            isDeviceConfiguration={false}
            configurationVersion={deviceTransmitterMemoryConfiguration?.version}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { DeviceConfigurationPage };
