import { Grid } from "@mui/material";
import { HandheldConnection } from "./HandheldConnection";
import { HandheldSettings } from "./HandheldSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";

interface Props {}

const HandheldConfigurationContainer = (props: Props) => {
  const isOpenHandheldConnectionActive = useSelector(
    (state: RootState) => state.device.isHandheldConnectionActive
  );

  return (
    <Grid container spacing={2}>
      <HandheldConnection />
      {isOpenHandheldConnectionActive && <HandheldSettings />}
    </Grid>
  );
};

export { HandheldConfigurationContainer };
