import { AccountCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STORAGE_JWT_KEY } from "../../constants/browserStorage";
import { SIGN_IN_PAGE } from "../../constants/pages";
import { clearDeviceSlice } from "../../stores/slices/deviceSlice";
import { setDrawerOpen } from "../../stores/slices/drawerSlice";
import { setUserToken } from "../../stores/slices/userSlice";
import { RootState } from "../../stores/store";
import { drawerWidth } from "../../theme/theme";
import { saveToStorage } from "../../utils/browserStorage";

const AppBar = () => {
  const open = useSelector((state: RootState) => state.drawer.open);
  const userToken = useSelector((state: RootState) => state.user.token);
  const username = useSelector((state: RootState) => state.user.username);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const deleteUserToken = () => {
    dispatch(clearDeviceSlice());
    dispatch(setUserToken(null));
    saveToStorage(localStorage, STORAGE_JWT_KEY, null);
    saveToStorage(sessionStorage, STORAGE_JWT_KEY, null);
    navigate(SIGN_IN_PAGE);
  };

  return (
    <MuiAppBar
      sx={{
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
        }),
        position: "absolute",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(setDrawerOpen(true))}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        </Typography>
        {userToken ? (
          <>
            {username}
            <IconButton onClick={handleMenuClick} color="inherit">
              <AccountCircle />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={deleteUserToken}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <Button color="inherit" onClick={deleteUserToken}>
            Login
          </Button>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export { AppBar };
