import { Grid, Typography } from "@mui/material";
import {
  PlatformSensorV0V1,
  PlatformSensorV2,
} from "../../../cobra-backend-client";
import { PlatformSensorClickCheckbox } from "../../common/PlatformSensorClickCheckbox";
import { PlatformSensorBlinkOutputComponent } from "./PlatformSensorBlinkOutputComponent";

interface Props {
  platformSensor: PlatformSensorV0V1 | PlatformSensorV2;
  statusMonitorPayloadVersion: string;
}

const PlatformSensorComponent = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={4}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>In 1..4 Single Click</Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <PlatformSensorClickCheckbox
          clickArray={props.platformSensor.singleClick}
          clickType="singleClick"
        />
      </Grid>

      <Grid
        item
        xs={4}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>In 1..4 Double Click</Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <PlatformSensorClickCheckbox
          clickArray={props.platformSensor.doubleClick}
          clickType="doubleClick"
        />
      </Grid>
      <PlatformSensorBlinkOutputComponent
        platformSensor={props.platformSensor}
        statusMonitorPayloadVersion={props.statusMonitorPayloadVersion}
      />
    </>
  );
};

export { PlatformSensorComponent };
