import { Container, Grid, useMediaQuery } from "@mui/material";
import { customizedTheme } from "../theme/theme";
import { HandheldConfigurationContainer } from "../components/device/handheld-configuration/HandheldConfigurationContainer";

const HandheldSettingsPage = () => {
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );

  return (
    <Container
      maxWidth={isScreenSizeGreaterThanSm ? "sm" : false}
      disableGutters
    >
      <HandheldConfigurationContainer />
    </Container>
  );
};

export { HandheldSettingsPage };
