import { Grid, Typography } from "@mui/material";
import {
  HandheldLimitation,
  SystemLimitation,
} from "../../../cobra-backend-client";
import { MonitorErrorCodeEnum } from "../../../models/dto/ws/monitoring/MonitorErrorCodeEnum";
import { SystemLimitationComponent } from "./SystemLimitationComponent";
import { Warning } from "./Warning";

interface Props {
  errorCode: MonitorErrorCodeEnum | undefined;
  handheldLimitation: HandheldLimitation | undefined;
  systemLimitation: SystemLimitation | undefined;
}

const DeviceStatusSystem = (props: Props) => {
  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h4">System</Typography>
        <Grid container rowSpacing={1}>
          {props.errorCode != null && <Warning errorCode={props.errorCode} />}
          {(props.handheldLimitation != null ||
            props.systemLimitation != null) && (
            <SystemLimitationComponent
              handheldLimitation={props.handheldLimitation}
              systemLimitation={props.systemLimitation}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { DeviceStatusSystem };
