import { Grid, Typography } from "@mui/material";
import {
  ActiveCableControlEnum,
  ActiveCableControlMessages,
} from "../../../models/dto/ws/monitoring/ActiveCableControlEnum";

interface Props {
  activeCableControl: ActiveCableControlEnum;
}

const ActiveCableControlComponent = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Active cable control </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Typography>
          {ActiveCableControlMessages[props.activeCableControl] ?? "Unknown"}
        </Typography>
      </Grid>
    </>
  );
};

export { ActiveCableControlComponent };
