import { IMessage } from "@stomp/stompjs";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Device } from "../cobra-backend-client";
import { TOPIC_ONLINE_DEVICES } from "../constants/topics";
import { useStompSubscription } from "../hooks/useStompSubscription";
import { setRegisteredDevices } from "../stores/slices/deviceSlice";
import { RootState } from "../stores/store";

interface Props {
  children: React.ReactNode;
}

const DeviceRegistryProvider = (props: Props) => {
  const dispatch = useDispatch();

  const registeredDevices = useSelector(
    (state: RootState) => state.device.registeredDevices
  );
  const registeredDevicesRef = useRef(registeredDevices);

  useEffect(() => {
    registeredDevicesRef.current = registeredDevices;
  }, [registeredDevices]);

  const onDeviceMessage = (message: IMessage) => {
    const device = JSON.parse(message.body) as Device;

    const devices = registeredDevicesRef.current.filter(
      (d) => d.deviceId != device.deviceId
    );

    if (device.online) {
      dispatch(setRegisteredDevices([...devices, device]));
    } else {
      dispatch(setRegisteredDevices([...devices]));
    }
  };

  useStompSubscription(TOPIC_ONLINE_DEVICES, onDeviceMessage);

  return <>{props.children}</>;
};

export { DeviceRegistryProvider };
