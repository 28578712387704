import { useSelector } from "react-redux";
import { RootState } from "../stores/store";


export const useIsDeviceRestartScheduled = (): boolean => {
    const registeredDevices = useSelector(
      (state: RootState) => state.device.registeredDevices
    );
    const currentConnectedDeviceId = useSelector(
      (state: RootState) => state.device.currentConnectedDeviceId
    );
  
    const connectedDevice = registeredDevices.find(
      (device) => device.deviceId === currentConnectedDeviceId
    );
  
    return !!connectedDevice?.restartScheduled;
  };
  