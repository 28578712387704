import { Chip, Grid, Switch, Typography } from "@mui/material";
import { useEffect } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;

  platformSensorEnabled: boolean | undefined;
  setPlatformSensorEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlatformSensorEnabled = (props: Props) => {
  useEffect(() => {
    props.setPlatformSensorEnabled(
      props.updatedDeviceConfiguration.imu?.plSensEnable ?? false
    );
  }, [props.updatedDeviceConfiguration.imu?.plSensEnable]);

  const onPlatformSensorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    props.setPlatformSensorEnabled(checked);

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      imu: {
        ...props.updatedDeviceConfiguration.imu,
        plSensEnable: checked,
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Platform sensor </Typography>
        <Chip
          sx={{ ml: 1 }}
          variant="outlined"
          label={props.platformSensorEnabled ? "on" : "off"}
          color={props.platformSensorEnabled ? "success" : "error"}
          size="small"
        />
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={props.platformSensorEnabled}
          onChange={onPlatformSensorChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
    </>
  );
};

export { PlatformSensorEnabled };
