import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  disableDeviceStatusMonitor,
  enableDeviceStatusMonitor,
} from "../components/common/deviceStatusMonitor";
import {
  CONTROLLER_INSTALLATION_PAGE,
  DEVICE_STATUS_PAGE,
} from "../constants/pages";
import { RootState } from "../stores/store";
import { useIsDeviceOnline } from "./useIsDeviceOnline";

export const useDeviceMonitor = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );
  const isDeviceStatusMonitorStarted = useSelector(
    (state: RootState) => state.device.isDeviceStatusMonitorStarted
  );

  const isDeviceOnline = useIsDeviceOnline();

  useEffect(() => {
    if (!currentConnectedDeviceId || !location || !isDeviceOnline) return;

    if (
      location.pathname === DEVICE_STATUS_PAGE ||
      location.pathname === CONTROLLER_INSTALLATION_PAGE
    ) {
      if (
        location.state?.from?.pathname === DEVICE_STATUS_PAGE ||
        location.state?.from?.pathname === CONTROLLER_INSTALLATION_PAGE
      ) {
        if (!isDeviceStatusMonitorStarted) {
          startMonitor(currentConnectedDeviceId);
        }
        return;
      }
      startMonitor(currentConnectedDeviceId);
    } else {
      if (
        location.state?.from?.pathname === DEVICE_STATUS_PAGE ||
        location.state?.from?.pathname === CONTROLLER_INSTALLATION_PAGE
      ) {
        stopMonitor(currentConnectedDeviceId);
      }
    }
  }, [
    currentConnectedDeviceId,
    isDeviceStatusMonitorStarted,
    isDeviceOnline,
    location,
  ]);

  const startMonitor = useCallback(
    async (deviceId: number | null) => {
      if (!deviceId) return;
      try {
        enableDeviceStatusMonitor(
          deviceId,
          isDeviceStatusMonitorStarted,
          dispatch
        );
      } catch (error) {}
    },
    [currentConnectedDeviceId]
  );

  const stopMonitor = useCallback(
    async (deviceId: number | null) => {
      if (!deviceId) return;

      try {
        disableDeviceStatusMonitor(deviceId, dispatch);
      } catch (error) {}
    },
    [currentConnectedDeviceId]
  );
};
