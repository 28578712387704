import { Box } from "@mui/material";
import { ReadOnlyCheckBox } from "./ReadOnlyCheckBox";

interface Props {
  clickArray: boolean[] | undefined;
  clickType: string;
}

const PlatformSensorClickCheckbox = (props: Props) => {
  if (!props.clickArray || props.clickArray.length === 0) {
    return <></>;
  }

  const middleIndex = Math.floor(props.clickArray.length / 2 - 1);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {props.clickArray.map((clickValue, index) => {
        const isMiddleIndex = index === middleIndex;
        const isCheckboxHidden = index > middleIndex;

        return (
          <Box
            key={`${props.clickType}-${index}`}
            component="span"
            sx={{
              pr: isMiddleIndex ? 1.5 : 0,
              visibility: isCheckboxHidden ? "hidden" : "visible",
            }}
          >
            <ReadOnlyCheckBox checked={clickValue} />
          </Box>
        );
      })}
    </Box>
  );
};

export { PlatformSensorClickCheckbox };
