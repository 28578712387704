import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { AngleSelector } from "./AngleSelector";
import { PlatformSensorAutomaticTiltUp } from "./PlatformSensorAutomaticTiltUp";
import { PlatformSensorBlinkModeEnabled } from "./PlatformSensorBlinkModeEnabled";
import { PlatformSensorEnabled } from "./PlatformSensorEnabled";
import { PlatformSensorFootSwitchMode } from "./PlatformSensorFootSwitchMode";
import { PlatformSensorOrientation } from "./PlatformSensorOrientation";
import { OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX } from "../../../constants/common";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const PlatformSensor = (props: Props) => {
  const [platformSensorEnabled, setPlatformSensorEnabled] = useState<boolean>(
    props.updatedDeviceConfiguration.imu?.plSensEnable ?? false
  );

  const [flashlightMinAngle, setFlashlightMinAngle] = useState(
    props.updatedDeviceConfiguration.angleSector?.minDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX
    ] ?? 0
  );
  const [flashlightMaxAngle, setFlashlightMaxAngle] = useState(
    props.updatedDeviceConfiguration.angleSector?.maxDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX
    ] ?? 0
  );

  const updateFlashlightAngles = () => {
    const newMinDeg = [
      ...(props.updatedDeviceConfiguration.angleSector?.minDeg ?? []),
    ];
    newMinDeg[OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX] = flashlightMinAngle;

    const newMaxDeg = [
      ...(props.updatedDeviceConfiguration.angleSector?.maxDeg ?? []),
    ];
    newMaxDeg[OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX] = flashlightMaxAngle;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      angleSector: {
        ...props.updatedDeviceConfiguration.angleSector,
        minDeg: newMinDeg,
        maxDeg: newMaxDeg,
      },
    };
    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Platform sensor
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
        }}
      >
        <PlatformSensorEnabled
          updatedDeviceConfiguration={props.updatedDeviceConfiguration}
          setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
          platformSensorEnabled={platformSensorEnabled}
          setPlatformSensorEnabled={setPlatformSensorEnabled}
        />
        {props.updatedDeviceConfiguration.imu?.plSensEnable && (
          <>
            <PlatformSensorOrientation
              updatedDeviceConfiguration={props.updatedDeviceConfiguration}
              setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
            />
            <AngleSelector
              minValue={flashlightMinAngle}
              maxValue={flashlightMaxAngle}
              setMinValue={setFlashlightMinAngle}
              setMaxValue={setFlashlightMaxAngle}
              mainTitle={`Flashlight angle ${
                OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX + 1
              }`}
              dialogTitle="Angle settings"
              updateDeviceConfiguration={updateFlashlightAngles}
            />
            <PlatformSensorBlinkModeEnabled
              updatedDeviceConfiguration={props.updatedDeviceConfiguration}
              setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
              platformSensorActive={platformSensorEnabled}
            />
          
            <PlatformSensorFootSwitchMode
              updatedDeviceConfiguration={props.updatedDeviceConfiguration}
              setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
              platformSensorActive={platformSensorEnabled}
            />
            <PlatformSensorAutomaticTiltUp
              updatedDeviceConfiguration={props.updatedDeviceConfiguration}
              setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
              platformSensorActive={platformSensorEnabled}
            />
          </>
        )}
      </Grid>
    </>
  );
};

export { PlatformSensor };
