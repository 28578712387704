import { Stack } from "@mui/material";
import React from "react";
import buttonCloseSvg from "../../assets/btnClose.svg";
import buttonDownSvg from "../../assets/btnDown.svg";
import buttonOpenSvg from "../../assets/btnOpen.svg";
import buttonUpSvg from "../../assets/btnUp.svg";

interface Props {
  activeFunctions: boolean[];
}
const ActiveFunctionIconDisplay = (props: Props) => {
  const icons = [
    <img src={buttonCloseSvg} style={{ width: "30px", height: "30px" }} />,
    <img src={buttonUpSvg} style={{ width: "30px", height: "30px" }} />,
    <img src={buttonOpenSvg} style={{ width: "30px", height: "30px" }} />,
    <img src={buttonDownSvg} style={{ width: "30px", height: "30px" }} />,
  ];

  return (
    <>
      {props.activeFunctions.map((value, index) =>
        value ? (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <React.Fragment key={index}>{icons[index]}</React.Fragment>
          </Stack>
        ) : (
          <></>
        )
      )}
    </>
  );
};

export { ActiveFunctionIconDisplay };
