import { Grid, Typography } from "@mui/material";
import { PlatformSensorV2 } from "../../../cobra-backend-client";

interface Props {
  platformSensorV2: PlatformSensorV2 | undefined;
}

const AngularRate = (props: Props) => {
  return (
    <>
      {props.platformSensorV2 != null && (
        <Grid container flexGrow={1} rowSpacing={1}>
          {props.platformSensorV2.angularRate != null && (
            <>
              <Grid item xs={6} sm={6}>
                <Typography>Angular rate</Typography>
              </Grid>
              <Grid item xs={6} sm={6} textAlign="right">
                {(props.platformSensorV2.angularRate / 10).toFixed(1)} °/s
              </Grid>
            </>
          )}

          {props.platformSensorV2.meanAngularRate != null && (
            <>
              <Grid item xs={6} sm={6}>
                <Typography>Peak angular rate of last movement</Typography>
              </Grid>
              <Grid item xs={6} sm={6} textAlign="right">
                {(props.platformSensorV2.meanAngularRate / 10).toFixed(1)} °/s
              </Grid>
            </>
          )}

          {props.platformSensorV2.maxAngularRate != null && (
            <>
              <Grid item xs={6} sm={6}>
                <Typography>Mean angular rate of last movement</Typography>
              </Grid>
              <Grid item xs={6} sm={6} textAlign="right">
                {(props.platformSensorV2.maxAngularRate / 10).toFixed(1)} °/s
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export { AngularRate };
