import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FirmwareUpdateContainer } from "../components/device/firmware/FirmwareUpdateContainer";
import { DeviceFirmwareVersionComponent } from "../components/device/version/DeviceFirmwareVersionComponent";
import { retrieveFirmwareVersionFromServer } from "../utils/retrieveFirmwareVersion";

const FirmwareUpdatePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    retrieveFirmwareVersionFromServer(dispatch);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DeviceFirmwareVersionComponent />
      </Grid>
      <Grid item md={12}>
        <FirmwareUpdateContainer />
      </Grid>
    </Grid>
  );
};

export { FirmwareUpdatePage };
