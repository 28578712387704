export const SIGN_IN_PAGE = "/signin";
export const LOGIN_PAGE = "/login";
export const DEVICE_STATUS_PAGE = "/device-status";
export const DEVICE_CONFIGURATION_PAGE = "/device-configuration";
export const DEVICE_FIRMWARE_UPDATE_PAGE = "/device-firmware-update";
export const DEVICE_CONFIGURATION_SETTINGS_PAGE =
  "/device-configuration-settings";
export const CONTROLLER_INSTALLATION_PAGE = "/controller-installation";
export const DEVICE_VERSION_PAGE = "/device-version";
export const HANDHELD_SETTINGS_PAGE = "/handheld-settings";

