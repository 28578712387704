import { useSelector } from "react-redux";
import { onlineDeviceOrAuthenticatedDrawerMenuItems } from "../../routes/onlineDeviceOrAuthenticatedDrawerMenuItems";
import { RootState } from "../../stores/store";
import {
  ExternalListItemButton,
  InternalListItemButton,
} from "./MenuListItemButton";

interface Props {}

const AppDrawerOnlineDeviceOrAuthenticatedMenuItem = (props: Props) => {
  const currentConnectedDeviceId = useSelector(
    (state: RootState) => state.device.currentConnectedDeviceId
  );

  const userToken = useSelector((state: RootState) => state.user.token);
  return (
    <>
      {(currentConnectedDeviceId != null || userToken != null) &&
        onlineDeviceOrAuthenticatedDrawerMenuItems.map((item) =>
          item.isExternakLink ? (
            <ExternalListItemButton key={item.text} item={item} />
          ) : (
            <InternalListItemButton key={item.text} item={item} />
          )
        )}
    </>
  );
};

export { AppDrawerOnlineDeviceOrAuthenticatedMenuItem };
