import { VariokeyItem } from "../cobra-backend-client";
import { CustomSelectOption } from "../components/common/CustomSelect";

export const UNKNOWN_VARIOKEY_NAME = "Unknown";

export const retrieveVariokeySelectOptions = (variokeys: VariokeyItem[]) => {
  const variokeySelectOptions = variokeys.map((variokey) => {
    return {
      label: variokey.name,
      value: variokey.index,
    } as CustomSelectOption;
  });

  return variokeySelectOptions;
};

export const findVariokeyByVariokeyIndex = (
  variokeys: VariokeyItem[],
  variokeyIndex: number
) => {
  let variokey = variokeys.find((variokey) => variokey.index === variokeyIndex);

  if (!variokey) {
    variokey = {
      name: UNKNOWN_VARIOKEY_NAME,
      index: variokeyIndex,
    } as VariokeyItem;
  }

  return variokey;
};
