import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import HandymanIcon from "@mui/icons-material/Handyman";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  CONTROLLER_INSTALLATION_PAGE,
  DEVICE_CONFIGURATION_PAGE,
  DEVICE_CONFIGURATION_SETTINGS_PAGE,
  DEVICE_FIRMWARE_UPDATE_PAGE,
  DEVICE_STATUS_PAGE,
  DEVICE_VERSION_PAGE,
  HANDHELD_SETTINGS_PAGE,
} from "../constants/pages";
import { CustomDrawerMenuItem } from "./CustomDrawerMenuItem";

export const onlineDeviceDrawerMenuItems: CustomDrawerMenuItem[] = [
  {
    text: "Status",
    icon: <MonitorHeartOutlinedIcon />,
    link: DEVICE_STATUS_PAGE,
    protected: false,
  },
  {
    text: "Controller configuration file",
    icon: <ContentPasteGoIcon />,
    link: DEVICE_CONFIGURATION_PAGE,
    protected: true,
  },
  {
    text: "Controller settings",
    icon: <HandymanIcon />,
    link: DEVICE_CONFIGURATION_SETTINGS_PAGE,
    protected: true,
  },
  {
    text: "Controller installation",
    icon: <HandymanIcon />,
    link: CONTROLLER_INSTALLATION_PAGE,
    protected: true,
  },
  {
    text: "Handheld settings",
    icon: <SettingsRemoteIcon />,
    link: HANDHELD_SETTINGS_PAGE,
    protected: true,
  },
  {
    text: "Version",
    icon: <SystemSecurityUpdateGoodIcon />,
    link: DEVICE_VERSION_PAGE,
    protected: true,
  },
  {
    text: "Firmware update",
    icon: <SystemUpdateAltIcon />,
    link: DEVICE_FIRMWARE_UPDATE_PAGE,
    protected: true,
  },
];
