import { Grid, Typography } from "@mui/material";
import {
  AbstractActivatedSensors,
  PlatformSensorV0V1,
  PlatformSensorV2,
} from "../../../cobra-backend-client";
import { PlatformAngle } from "./PlatformAngle";
import { PlatformSensorComponent } from "./PlatformSensorComponent";
import { PlatformsensorWarningComponent } from "./PlatformSensorWarningComponent";

interface Props {
  platformAngle: number | undefined;
  activatedSensors: AbstractActivatedSensors | undefined;
  platformSensor: PlatformSensorV0V1 | PlatformSensorV2 | undefined;
  statusMonitorPayloadVersion: string | undefined;
}

const DeviceStatusPlatformSensorContainer = (props: Props) => {
  return (
    <>
      {props.activatedSensors?.plSensor && (
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h4">Platform sensor</Typography>
            <Grid container rowSpacing={1}>
              <PlatformsensorWarningComponent
                platformSensor={props.platformSensor}
                statusMonitorPayloadVersion={props.statusMonitorPayloadVersion}
              />
              {props.platformAngle != null &&
                props.activatedSensors?.plSensor != null && (
                  <PlatformAngle
                    platformAngle={props.platformAngle}
                    plSensor={props.activatedSensors.plSensor}
                  />
                )}

              {props.platformSensor != null &&
                props.statusMonitorPayloadVersion != null && (
                  <PlatformSensorComponent
                    platformSensor={props.platformSensor}
                    statusMonitorPayloadVersion={
                      props.statusMonitorPayloadVersion
                    }
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { DeviceStatusPlatformSensorContainer };
