import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VariokeyItem } from "../../cobra-backend-client";

interface VariokeysState {
  variokeys: VariokeyItem[];
}

const initialState: VariokeysState = {
  variokeys: [],
};

const variokeysSlice = createSlice({
  name: "variokeys",
  initialState,
  reducers: {
    setVariokeys: (state, action: PayloadAction<VariokeyItem[]>) => {
      state.variokeys = action.payload;
    },
  },
});

export const { setVariokeys } = variokeysSlice.actions;

export default variokeysSlice.reducer;
