import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { COBRA_API } from "../../../api/config";
import { RootState } from "../../../stores/store";
import {
  disableDeviceStatusMonitor,
  enableDeviceStatusMonitor,
} from "../../common/deviceStatusMonitor";

interface Props {
  deviceId: number;
  showAngleCalibrationProgress: boolean;
}

const DeviceRestart = (props: Props) => {
  const dispatch = useDispatch();
  const isDeviceStatusMonitorStarted = useSelector(
    (state: RootState) => state.device.isDeviceStatusMonitorStarted
  );

  const onControllerRestartClick = async () => {
    try {
      disableDeviceStatusMonitor(props.deviceId, dispatch);

      const success = (
        await COBRA_API.DeviceRegistry.attemptDeviceRestart(props.deviceId)
      ).data;

      if (success) {
        enableDeviceStatusMonitor(
          props.deviceId,
          isDeviceStatusMonitorStarted,
          dispatch
        );
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <>
      <Grid container flexGrow={1}>
        <Grid item xs={6} sm={6}>
          <Typography>Restart controller</Typography>
        </Grid>
        <Grid item xs={6} sm={6} textAlign="right">
          <Button
            disabled={props.showAngleCalibrationProgress}
            onClick={onControllerRestartClick}
          >
            <RestartAltIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export { DeviceRestart };
