import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DeviceConfiguration,
  VariokeyItem,
} from "../../../cobra-backend-client";
import { RootState } from "../../../stores/store";
import {
  findVariokeyByVariokeyIndex,
  retrieveVariokeySelectOptions,
  UNKNOWN_VARIOKEY_NAME,
} from "../../../utils/variokeyUtils";
import { CustomSelect, CustomSelectOption } from "../../common/CustomSelect";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const VariokeyContainer = (props: Props) => {
  const variokeys = useSelector(
    (state: RootState) => state.variokeys.variokeys
  );
  const [selectOptions, setSelectOptions] = useState<CustomSelectOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<VariokeyItem>();

  useEffect(() => {
    const variokey = findVariokeyByVariokeyIndex(
      variokeys,
      props.updatedDeviceConfiguration.varioKey
    );
    if (variokey.name === UNKNOWN_VARIOKEY_NAME) {
      setSelectOptions(retrieveVariokeySelectOptions([...variokeys, variokey]));
    } else {
      setSelectOptions(retrieveVariokeySelectOptions([...variokeys]));
    }

    setSelectedValue({ ...variokey });
  }, [variokeys, props.updatedDeviceConfiguration.varioKey]);

  const handleChange = (newValue: number) => {
    var variokey = variokeys.find((variokey) => variokey.index === newValue);
    setSelectedValue(variokey);

    const updatedConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      varioKey: variokey?.index ?? 0,
    };

    props.setUpdatedDeiceConfiguration(updatedConfiguration);
  };

  const getSelectDisabledValues = () => {
    var disabledSelectVariokey = selectOptions.find(
      (v) => v.label === UNKNOWN_VARIOKEY_NAME
    )?.value;
    if (!disabledSelectVariokey) {
      return [];
    }
    return [disabledSelectVariokey];
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Variokey
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
        }}
      >
        <Grid
          item
          xs={4}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
            pt: 0,
          }}
        >
          <Typography>Company Variokey</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <CustomSelect
            selectedValue={selectedValue?.index?.toString() ?? ""}
            disabledValues={getSelectDisabledValues()}
            options={selectOptions}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { VariokeyContainer };
