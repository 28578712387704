import { Container, LinearProgress, useMediaQuery } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useStompClient } from "react-stomp-hooks";
import { DeviceStatusContainer } from "../components/device/device-status/DeviceStatusContainer";
import { customizedTheme } from "../theme/theme";

const STOMP_CLIENT_MAX_CONNECTION_TIME = 6500;

interface Props {}

const DeviceStatusPage = memo(function DeviceStatusPage(props: Props) {
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );
  const stompClient = useStompClient();

  const [stompClientLoading, setStompClientLoading] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!stompClient?.connected) {
      timeoutId = setTimeout(() => {
        setStompClientLoading(false);
      }, STOMP_CLIENT_MAX_CONNECTION_TIME);
    } else {
      setStompClientLoading(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [stompClient?.connected]);

  return (
    <Container
      maxWidth={isScreenSizeGreaterThanSm ? "sm" : false}
      disableGutters
    >
      {stompClientLoading ? <LinearProgress /> : <DeviceStatusContainer />}
    </Container>
  );
});

export { DeviceStatusPage };
