export const DEVICE_STATUS_INDEX = 0;

export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX = 0;
export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_2_INDEX = 1;
export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_3_INDEX = 2;
export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_4_INDEX = 3;

export const STATUS_MONITOR_VERSION_0 = "0.0.0";
export const STATUS_MONITOR_VERSION_1 = "1.0.0";
export const STATUS_MONITOR_VERSION_2 = "2.0.0";
